.space-name {
	font-family: $font-family-base;
	font-weight: $font-weight-bold;
	display: block;
	font-size: 40px;
	width: 100%;

	@include media-breakpoint-down(xl) { 
		line-height: 1;
		margin-bottom: 36px;
	}

	@include media-breakpoint-up(xl) { 
		line-height: 1.2;
		margin-bottom: 59px;
	}
}