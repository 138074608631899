.title-with-line {
	border-bottom: 2px solid $gray-dark;
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		padding-bottom: 25px;
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 15px;
	}
}

.event-date {
	font-family: $font-family-brand-mono;
	font-weight: $font-weight-regular;
	line-height: 1;
	display: flex;
	align-items: flex-start;
	padding-bottom: 6px;

	&.with-padding-top {

		@include media-breakpoint-up(lg) {
			padding-top: 25px;
		}
	}
	
	sup {
		font-size: 58%;
	}

	@include media-breakpoint-up(lg) {
		font-size: 92px;

		sup {
			top: 25px;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: 2.5rem;

		sup {
			top: 11px;
		}
	}
}

.event-time {
	font-family: $font-family-brand-mono;

	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}