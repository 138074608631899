.fun-activities {
	position: relative;
	
	@include media-breakpoint-up(lg) {  
		margin-bottom: 150px;
		padding-top: 172px;
	}
	
	@include media-breakpoint-down(lg) {
		padding-top: 176px;
	}

	img {

		@include media-breakpoint-down(lg) { 
			margin: 0 auto;
			display: block;
		}
	}

	.activities {
		position: relative;

		@include media-breakpoint-up(lg) { 
			max-width: 410px;
		}

		@include media-breakpoint-down(md) { 
			padding: 46px 30px 25px;
			max-width: 480px;
			margin: 0 auto;
		}

		&::before {

			@include media-breakpoint-up(lg) {
				content: "";
				display: block;
				position: absolute;
				top: 8px;
				left: -39.1%;
				width: 80px;
				height: 1px;
				background-color: $gray-dark;
			}
		}
	}

	.title-h3 {
		line-height: 1.2;
		position: relative;

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}

		@include media-breakpoint-up(lg) { 
			margin-left: 33.3%;
			margin-bottom: 68px;
		}
		
		@include media-breakpoint-down(md) {  
			text-align: center;
			max-width: 480px;
			margin: 0 auto;
			padding: 0 $container-padding 45px;
		}

		&::before {

			@include media-breakpoint-down(md) {
				content: "";
				display: block;
				position: absolute;
				top: -126px;
				left: 50%;
				transform: translate3d(-50%, 0, 0);
				width: 1px;
				height: 50px;
				background-color: $gray-dark;
			}
		}

		&-normal {
			line-height: 1.2;
			font-weight: $font-weight-regular;
			
			@include media-breakpoint-up(xl) {  
				font-size: 40px;
				margin-bottom: 61px;
			}

			@include media-breakpoint-down(xl) {  
				font-size: 20px;
			}

			@include media-breakpoint-down(lg) { 
				margin-bottom: 38px;
			}
		}
	}

	p {

		@include media-breakpoint-up(xl) {  
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 83px;
			min-height: 228px;
		}

		@include media-breakpoint-down(xl) {  
			line-height: 26px;
		}
	}
}