.logo {
	
	float: left;
	z-index: $zindex-navbar;

	@include media-breakpoint-up(lg) {
		top: 40px;
		left: 40px;
		width: 180px;
		height: 36px;
		position: fixed;
	}

	@include media-breakpoint-down(md) {
		top: 25px;
		right: 30px;
		width: 110px;
		height: 22px;
		position:absolute;
	}

	@media screen and (max-height: 750px) {
		top: 25px;
		right: 30px;
	}

	img {
		display: block;
		width: 100%;
	}
}

