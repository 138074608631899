.community-right-desc {
	align-self: flex-end;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
		line-height: 30px;
		max-width: 400px;
		padding-bottom: 70px;
	}

	@include media-breakpoint-down(lg) {
		padding: 45px $container-padding 0;
		font-size: 16px;
		line-height: 26px;
		max-width: 480px;
	}

	.link-with-border-red {
		font-size: 16px;
		line-height: 24px;
	}
}