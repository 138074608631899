.btn-group {

	.btn {
		line-height: 1;
		font-family: $font-family-brand-mono;
		font-weight: $font-weight-regular;

		&.active {
			background-color: $brand-primary;
			border-color: $brand-primary;

			&:hover {
				opacity: 1;
			}
		}

		&:hover {
			opacity: .5;
			background-color: $brand-primary;
			border-color: $brand-primary;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.btn {
			padding: 16px 0;
			font-size: 16px;
			min-width: 50px;
			margin-right: 11px;
		}
	}

	@include media-breakpoint-down(md) {
		.btn {
			padding: 9px 0;
			font-size: 14px;
			min-width: 34px;
			margin-right: 6px;
		}
	}
}