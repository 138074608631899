.pride-ourselves {
	text-align: center;

	@include media-breakpoint-down(md) {
		max-width: 480px;
		margin: 0 auto;
		padding: 126px $container-padding 36px;	
	}

	.title-h2 {
		line-height: 1.2;
		position: relative;

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom: 40px;
		}

		&:before {

			@include media-breakpoint-down(lg) {
				content: "";
				display: block;
				position: absolute;
				top: -126px;
				left: 50%;
				width: 1px;
				height: 50px;
				background-color: $gray-dark;
				transform: translate3d(-50%, 0, 0);
			}
		}
	}

	.title-h3 {
		line-height: 1.2;
		position: relative;

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 168px;
		}
		
		@include media-breakpoint-down(lg) {  
			font-size: 20px;
			font-weight: normal;
		}
	}
}