@font-face {
  font-family: 'MaisonNeue';
  src: url('/assets/MaisonNeueWEB-Bold.woff2') format('woff2'),
       url('/assets/MaisonNeueWEB-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('/assets/MaisonNeueWEB-Book.woff2') format('woff2'),
       url('/assets/MaisonNeueWEB-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonMono';
  src: url('/assets/MaisonMonoWEB-Bold.woff2') format('woff2'),
       url('/assets/MaisonMonoWEB-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}