.submit-success {
	background: url('/assets/img/site/red-pattern.png') repeat 50% 50%;
	display: flex;
	align-items: center;
 	color: $white;
 	line-height: 1;
 	font-weight: $font-weight-bold;
 	height: 100vh;

 	@include media-breakpoint-up(lg) {
		font-size: 92px;
	}

  @include media-breakpoint-down(md) {	
		padding: 0 $container-padding;
		font-size: 40px;
	}
}