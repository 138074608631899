.contact-top-block {
	position: relative;

	@include media-breakpoint-up(lg) {
		width: 100%;
		height: 100vh;
		min-height: 880px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 30px;
		margin-bottom: 72px;
	}

	&::before {
		content: "";
		position: absolute;
		display: block;
		height: 100%;
		background-color: $blue-lightest-color-with-transparent;

		@include media-breakpoint-down(lg) {
			right: 0;
			top: 91px;
			width: 25%;
			height: calc( 100% - 91px );
		}

		@include media-breakpoint-up(lg) {
			right: 0;
			top: 0;
			width: 37.5%;
			bottom: 0;
		}
	}

	.display-2 {
		line-height: 1;

		@include media-breakpoint-up(xl) { 
			font-size: 92px;
		}

		@include media-breakpoint-down(xl) { 
			font-size: 40px;
		}

		@include media-breakpoint-up(lg) {
			padding-top: 163px;
			margin-bottom: 146px;
		}
		
		@include media-breakpoint-down(lg) {
			padding-top: 87px;
			padding-bottom: 35px;
		}
	}

	.contact-info {
		font-weight: $font-weight-bold;
		flex-wrap: wrap;
		display: flex;

		@include media-breakpoint-up(xl) { 
			font-size: 20px;
			line-height: 30px;
		}

		@include media-breakpoint-down(xl) { 
			font-size: 16px;
			line-height: 26px;
		}

		div {
			position: relative;

			@include media-breakpoint-down(lg) {
				padding-top: 22px;
				padding-bottom: 42px;
			}

			&::before {
		    content: "";
		    background-color: $gray-dark;
		    height: 1px;
		    position: absolute;

		    @include media-breakpoint-up(xl) {
		    	top: 8px;
					left: -33.33%;
					width: 80px;
		    }

		    @include media-breakpoint-down(xl) {
					top: 8px;
					left: -30%;
					width: 50px;
				}

				@include media-breakpoint-down(lg) {
					top: 0;
					left: 0;
					width: 50px;
				}
			}
		}

		.share-links {
			padding-bottom: 0;
			
			a{
				margin-right: 20px;
			}
		}
	}
}