.community-section {
	position: relative;

	@include media-breakpoint-up(xl) {
		padding-top: 175px;
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 180px;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 285px;
	}
}