.team-content {
	position: relative;
	text-align: center;

	@include media-breakpoint-up(lg) {
		padding: 319px 0 164px;
	}

	@include media-breakpoint-down(md) { 
		padding: 64px 30px 0;
		min-height: 320px;
		display: flex;
		align-items: center;
		max-width: 480px;
		margin: 0 auto;
	}

	.title-h3 {

		@include media-breakpoint-down(lg) { 
			font-size: 20px;
			line-height: 1.2;
			margin-bottom: 12px;
		}
	}

	.big-text {

		@include media-breakpoint-up(lg) {
			top: 159px;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			text-align: center;
		}

		@include media-breakpoint-down(md) { 
			top: 124px;
			left: calc(50% - 100px);
			transform: rotate(270deg) translate3d(0, -50%, 0);
		}
	}
}
