.about-team {
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-top: 360px;
	}

	@include media-breakpoint-down(md) { 
		padding-top: 130px;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		background-color: $primary-lightest-color-with-transparent;

		@include media-breakpoint-up(lg) { 
			left: 33.3333333334%;
			top: 180px;
			width: 16.6666666667%;
			height: 1181px;
		}

		@include media-breakpoint-down(md) { 
			top: 80px;
			left: 50%;
			width: 50%;
			height: 470px;
			transform: translate3d(-50%, 0, 0);
		}
	}

	img {
		position: relative;
	}
}