.img-with-letter {
	z-index: 3;

	@include media-breakpoint-up(lg) {
		position: relative;
	}

	@include media-breakpoint-down(md) {
		position: absolute;
		width: 50%;
		right: 0;
		top: 50%;
		transform: translate3d(0, -50%, 0);
	}

	.big-text {
		text-transform: uppercase;
		color: $brand-primary;
		z-index: 1;
		mix-blend-mode: overlay;

		@include media-breakpoint-up(lg) {
			top: -80px;
			left: 16px;
		}

		@include media-breakpoint-down(md) {
			top: -40px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	picture {
		text-align: right;
	}
}

.video-button {
	position: absolute;
	z-index: 2;
	cursor: pointer;

  @include media-breakpoint-up(lg) {
  	top: 50%;
		left: 50%;
		width: 50px;
		height: 82px;
		transform: translate3d(-50%, -50%, 0);

	  &:hover {

	  	&::before {
		    transform: scale3d(1.2, 1.2, 1.2);
		    transition: transform .2s linear;
	  	}
	  }
	}

	@include media-breakpoint-down(md) {
		top: 52px;
		left: -15px;
	}

	&::before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		left: 0;

		@include media-breakpoint-up(lg) {
			border-top: 41px solid $transparent;
		  border-bottom: 41px solid $transparent;
		  border-left: 50px solid $brand-primary;
		  transition: transform .2s linear;
	  	transform: scale3d(1, 1, 1);
		}

		@include media-breakpoint-down(md) {
			border-top: 25px solid $transparent;
		  border-bottom: 25px solid $transparent;
		  border-left: 30px solid $brand-primary;
		}
	}
}