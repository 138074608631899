.spaces-title {

	@include media-breakpoint-up(lg) {
		margin-bottom: 163px;
	}

	@include media-breakpoint-down(md) {
		font-size: 20px;
		margin-bottom: 72px;
	}
}