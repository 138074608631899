.booking-form {
	
	@include media-breakpoint-up(lg) {
		background: url("/assets/img/site/booking-bg.jpg") 50% 0% /cover no-repeat;
	}

	@include media-breakpoint-down(md) {
		background-image: url("/assets/img/site/pattern-2.png");
	}

	.booking-title {
		font-weight: 700;
		color: $gray-dark;
		text-align: center;

		@include media-breakpoint-up(lg) {
			font-size: 30px;
			margin: 0 0 105px 0;
		}

		@include media-breakpoint-down(md) {
			font-size: 20px;
			margin: 0 0 69px 0;
		}
	}

	.booking-container {
		width: 100%;
		margin: 0 auto;

		@include media-breakpoint-up(lg) {
			padding-top: 116px;
			margin-bottom: 120px;
		}

		@include media-breakpoint-down(md) {
			padding: 124px 30px 0 30px;
			margin-bottom: 80px;
		}

		@include media-breakpoint-down(md) {
			max-width: 690px;
		}

		@include media-breakpoint-only(lg) {
			max-width:720px;
		}

		@include media-breakpoint-up(xl) {
			max-width:960px;
		}

		.person-info {
			padding-top: 50px;
			
			@include media-breakpoint-up(lg) {
				margin-bottom: 64px;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 81px;
			}
		}

		.btn {
			max-width: 300px;
			width: 100%;
			margin: 0 auto;
			display: block;
			font-size: 14px;
			@include media-breakpoint-up(lg) {
				padding: 26px 0;
			}

			@include media-breakpoint-down(md) {
				padding: 22px 0 21px 0;
			}
		}

		.form-group {
			margin: 0;
			position: relative;

			* {
				-webkit-appearance: none;
			}

			label {
				font-size: 12px;
				font-weight: 400;
				color: $gray_7f;
				margin: 0 0 14px 0;
			}

			.bgc-img {
				background-color: $white;
				@include media-breakpoint-up(lg) {
					&:after {
						content:'';
						position: absolute;
						top: 55px;
						right: 19px;
						background: $white url("/assets/img/site/dropdown-plus.svg") 50% 50% no-repeat;
						background-size: 10px;
						width: 32px;
						height: 32px;
						z-index:1;
					}
				}

				@include media-breakpoint-down(md) {
					&:after {
						content:'';
						position: absolute;
						top: 49px;
						right: 9px;
						background: $white url("/assets/img/site/dropdown-plus.svg") 50% 50% no-repeat;
						background-size: 10px;
						width: 32px;
						height: 32px;
						z-index:1;
					}
				}

				&.change-icon {
					@include media-breakpoint-up(lg) {
						&:after {
							content:'';
							position: absolute;
							top: 55px;
							right: 19px;
							background: $white url("/assets/img/site/dropdown-minus.svg") 50% 50% no-repeat;
							background-size: 14px;
							width: 32px;
							height: 32px;
							z-index:1;
						}
					}

					@include media-breakpoint-down(md) {
						&:after {
							content:'';
							position: absolute;
							top: 49px;
							right: 9px;
							background: $white url("/assets/img/site/dropdown-minus.svg") 50% 50% no-repeat;
							background-size: 14px;
							width: 32px;
							height: 32px;
							z-index:1;
						}
					}
				}

				select:-moz-focusring {
				    color: transparent;
				    text-shadow: 0 0 0 #000;
				}

				select {
					background-color: transparent;
					z-index: 2;
				}
			}

			.form-control {
				appearance: none;
				border-radius: 0;
				border: none;
				outline: none;
				border-bottom: 2px solid $gray_e6;
				background-color: $white;
				font-size: 14px;
				color: $gray-dark;
				position:relative;
				z-index:1;

				&::placeholder {
					font-size: 14px;
					color: $gray-dark;
				}

				@include media-breakpoint-up(lg) {
					height: 72px;
					padding: 2px 19px 2px 30px;
				}

				@include media-breakpoint-down(md) {
					height: 62px;
					padding: 2px 8px 2px 20px;
				}

				&.active-input {
					border-bottom: 2px solid $gray-dark;
					box-shadow: none;
				}

				&.error-input {
					border-bottom: 2px solid #ff4e4e;
					box-shadow: none;
				}

				&.switch-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				&.calendar {
					background-color: $white;
					@include media-breakpoint-up(lg) {
						&:after {
							content: '';
							width: 32px;
							height: 32px;
							background:url("/assets/img/site/calendar.svg") center center no-repeat;
							background-size: 24px;
							display: inline-block;
							margin-right: 6px;
						}
					}

					@include media-breakpoint-down(md) {
						&:after {
							content: '';
							width: 32px;
							height: 32px;
							background: $white url("/assets/img/site/calendar.svg") center center no-repeat;
							background-size: 20px;
							display: inline-block;
							margin-right: 6px;
						}
					}
				}
			}

			.textarea {
				padding-bottom: 27px;
				min-height: 120px;
				resize: none;

				@include media-breakpoint-up(lg) {
					padding-top: 27px;
				}

				@include media-breakpoint-down(md) {
					padding-top: 22px;
				}	
			}

			@include media-breakpoint-down(md) {
				.erc-event {
					height: 60px;
				}

				.erc-event-title {
					font-size: 14px;
				}
			}
		}

		.booking-block {
			display: flex;
			justify-content: space-between;
			
			@include media-breakpoint-up(lg) {
				margin: 0 -26px 21px -26px;
			}

			@include media-breakpoint-down(md) {
				flex-direction: column;
				margin: 0 -26px 21px -26px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.booking-item {
			max-width: 100%;
			width: 100%;

			@include media-breakpoint-up(lg) {
				padding: 0 26px;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 24px;
				padding: 0 26px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.block-454 {
				@include media-breakpoint-up(lg) {
					padding: 0 26px;
    				width: 50%;
				}

				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}

			&.active {
				.form-group {
					.switch-right {
						background: url("/assets/img/site/dropdown-minus.svg") center center no-repeat;
						background-size: 14px;
					}
				}

				.booking-drop-down-container {
					display: block;
				}
			}
		}

		.switch {
			.cmn-toggle {
			  position: absolute;
			  margin-left: -9999px;
			  visibility: hidden;

			 	+ label {
				  	display: block;
					position: relative;
					cursor: pointer;
					outline: none;
					user-select: none;
					margin: 0;
				}

			  	&.cmn-toggle-round {
			  		+ label {
				  		padding: 2px;
						width: 52px;
						height: 10px;
						background-color: #dddddd;
						border-radius: 60px;

						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 100%;
							top: 0px;
							left: 0px;
							bottom: 0px;
							background-color: $gray_d2;
							border-radius: 60px;
							transition: background 0.4s;
						}

						&:after {
							content: "";
							display: block;
							position: absolute;
							top: -8px;
							left: 0px;
							bottom: 1px;
							width: 25px;
							height: 25px;
							background-color: $gray-dark;
							border-radius: 100%;
							border: 2px solid #fff;
							transition: margin 0.4s;
						}
			  		}

			  		&:checked {
			  			+ label {
			  				&:before {
			  					background-color: #f6d2da;
			  				}

			  				&:after {
			  					margin-left: 30px;
			  					background-color: #d01c45;
			  				}
			  			}
			  		}
			  	}
			}
		}

		.switch-right {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: url("/assets/img/site/dropdown-plus.svg") center center no-repeat;
			background-size: 10px;
			cursor: pointer;
		}
	}

	.booking-drop-down-container {
	 		display: none;
	}

	.booking-drop-down {
		background-color: $white;
		border-bottom: 2px solid $gray-dark;

			@include media-breakpoint-up(lg) {
				padding: 48px 30px;
			}

			@include media-breakpoint-down(md) {
				padding: 48px 0px 0 0;
			}

		.drop-down-block {
			@include media-breakpoint-up(lg) {
				margin-bottom: 28px;
			}
			@include media-breakpoint-down(md) {
				border-bottom: 1px solid $gray_e0;
				margin-bottom: 48px;
			}

			&:last-child {
				margin-bottom: 0;
				border-bottom:  none;
			}
			.title-drop-down {

				@include media-breakpoint-up(lg) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 39px;
				}

				@include media-breakpoint-down(md) {
					display: block;
					text-align: center;
					margin-bottom: 47px;
				}

				h4 {
					font-size: 14px;
					font-weight:700;
					color: $gray-dark
				}

				span {
					font-size: 12px;
					font-weight: 400;
					color: #a1a1a1;
					@include media-breakpoint-up(lg) {
						display: block;
					}

					@include media-breakpoint-down(md) {
						display: none;
					}

					em {
						color: #e7a1ac;
						font-style: normal;
					}
				}
			}

			.selection-list {
				padding: 0;
				list-style-type: none;
				display: flex;
				flex-wrap: wrap;

				@include media-breakpoint-up(lg) {
					margin:0 -26px;
				}

				@include media-breakpoint-down(md) {
					flex-direction: column;
					margin: 0;
				}

				.selection-list-item {
					@include media-breakpoint-up(lg) {
						padding: 0 26px;
						width: 50%;
					}

					@include media-breakpoint-down(md) {
						padding:0px;
						width: 100%;
					}
				}

				.selection-list-body {
					display: flex;
					justify-content: space-between;
					position: relative;

					@include media-breakpoint-up(lg) {
						border: 1px solid $gray_e0;
						padding: 17px 26px 14px 30px;
						margin-bottom: 20px;
					}

					@include media-breakpoint-down(md) {
						flex-direction: column;
						border-top: 1px solid $gray_e0;
						padding: 21px 13px 5px 20px;
						margin-bottom: 5px;
					}

					.hover-info {
						background-color: $white;
						box-shadow: 0px 2px 30px 0px rgba(31, 31, 31, 0.07);
						margin: 0;

						@include media-breakpoint-up(lg) {
							width: 100.5%;
							left: -1px;
							padding: 26px 30px 23px 30px;
						}

						@include media-breakpoint-down(md) {
							width: 100%;
							left: 0px;
							padding: 26px 20px 23px 20px;
						}

						.hover-content {
							border-bottom: 1px solid $gray_e0;
							
							@include media-breakpoint-up(lg) {
								margin-bottom: 24px;
							}

							@include media-breakpoint-down(md) {
								margin-bottom: 24px;
							}

							h2 {
								line-height: 20px;
								color: $gray-dark;
								margin: 0 0 20px 0;
								font-weight: 700;
								@include media-breakpoint-up(lg) {
									font-size: 14px;
								}

								@include media-breakpoint-down(md) {
									font-size: 12px;
								} 
							}

							p {
								color: $gray-dark;
								margin: 0;
								font-weight: 400;
								padding-bottom: 27px;
								
								@include media-breakpoint-up(lg) {
									font-size: 14px;
									padding-right: 30px;
									line-height: 20px;
								}

								@include media-breakpoint-down(md) {
									font-size: 12px;
									padding-right: 0px;
									line-height: 18px;
								} 
							}
						}

						.hover-total {
							h3 {
								margin: 0 0 0px 0;
								color: $gray-dark;
								font-weight: 400;

								@include media-breakpoint-up(lg) {
									font-size: 14px;
									line-height: 20px;
								}

								@include media-breakpoint-down(md) {
									font-size: 12px;
									line-height: 18px;
								}
							}

							span {
								color: $brand-primary;
								font-weight: 700;
								display: block;
								font-family: $font-family-brand-mono;
								@include media-breakpoint-down(md) {
									font-size: 12px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}

			.input {
				@include media-breakpoint-up(lg) {
					display: flex;
					align-items: center;
					flex: 1;
				}
				@include media-breakpoint-down(md) {
					margin-bottom: 8px;
    				
				}

				.group-info {
					@include media-breakpoint-up(lg) {
						display: flex;
						align-items:center;
						flex: 1;
					}

					@include media-breakpoint-down(md) {
						display: table;
						width: 100%;
						vertical-align: middle;
					}


					&.open {
						.info {
							.info-icon {
								.fill {
									fill: $gray-dark;
								}
							}
						}
					}
				}

				.input-checkbox {
					display: table-cell;
  					vertical-align: middle;
					position:relative;
					z-index: 30;

					input {
						appearance: none;
						border-radius: 0;
						border: none;
						position: absolute;
	  					left: 0px;
	  					width: 18px;
	  					height: 18px;
	  					z-index:2;
	  					cursor: pointer;
	  					@include media-breakpoint-up(lg) {
							top: 0;
	  					}

	  					@include media-breakpoint-down(md) {
							top: 8px;
						}

						&:checked {
							background-color: $brand-primary;
							display: flex;
							align-items:center;
							justify-content:center;
							
							&:after {
								content:'';
								width: 4px;
								height: 4px;
								background-color: #fff;
								display: block;
							}
						}
					}

					label {
						background-color: $red_e7;
						width: 18px;
						height: 18px;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 18px;
						margin-bottom: 3px;
						cursor: pointer;
						z-index:1;

						&:after {
							content:'';
							width: 4px;
							height: 4px;
							background-color: transparent;
							display: block;
						}
					}
				}

				.info {
					position:relative;
					border: 0 none;
					cursor: pointer;
					
					@include media-breakpoint-up(lg) {
						flex: 1;
					}

					@include media-breakpoint-down(md) {
						display: table-cell;
						width: 100%;
						max-width:0;
					}

					h6 {
						font-size: 14px;
						line-height: 18px;
						color: $gray-dark;
						font-weight: 500;
						
						@include media-breakpoint-up(lg) {
							margin: 0 0 1px 0;
						}

						@include media-breakpoint-down(md) {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							margin: 0 40px 1px 0;
						}

					}

					span {
						font-size: 12px;
						line-height: 18px;
						color: $gray_7f;
						display: block;

						em {
							margin-right: 3px;
							display: inline-block;
							font-style: normal;
						}
					}

					&.dropdown-toggle {
						&::after {
							display: none;
						}
					}

					.info-icon {
						width: 18px;
						position: absolute;
						top: 8px;
						@include media-breakpoint-up(lg) {
							display: none;
						}

						@include media-breakpoint-down(md) {
							display: block;
							right: 7px;
						}
					}
				}
			}

			.selection-number {
				@include media-breakpoint-up(lg) {
					padding-top: 0px;
					width: 69px;
					display: flex;
					align-items: center;
				}

				@include media-breakpoint-down(md) {
					margin-bottom: 9px;
					width: 100%;
					display: table;
				}

				&.invisible {
					display: none!important;
				}

				.left {
					width: 32px;
					height: 32px;
					display: table-cell;
					vertical-align: middle;
					cursor: pointer;

					@include media-breakpoint-up(lg) {
						display: flex;
					}

					img {
						width: 10px;
						display: block;
						margin-left: 4px;
					}
				}

				input {
					-webkit-appearance: none;
					border-radius: 0;
					border: none;
					font-size: 12px;
					font-weight: 700;
					color: $gray-dark;

					@include media-breakpoint-up(lg) {
						width: 42px;
						text-align: center;
						margin-top: 6px;
						padding-top: 3px;
						display: flex;
					}

					@include media-breakpoint-down(md) {
						text-align:center;
						width: 100%;
						padding-top: 8px;
					}
				}

				.right {
					width: 32px;
					height: 32px;
					display: table-cell;
					vertical-align: middle;
					cursor: pointer;

					@include media-breakpoint-up(lg) {
						display: flex;
					}

					img {
						width: 10px;
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

/*request send*/
.request-send {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-weight: 700;
		color: $gray-dark;
		@include media-breakpoint-up(lg) {
			font-size: 30px;
		}

		@include media-breakpoint-down(md) {
			font-size: 20px;
		}
	}
}