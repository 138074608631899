.partners {
	position: relative;
	
	@include media-breakpoint-up(lg) {
		padding-bottom: 250px;
		margin-bottom: 0;
	}

	.col-md-2 {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;

		@include media-breakpoint-up(md) {
			min-height: 240px;

			&:nth-child(-2n+4), 
			&:nth-child(4) ~ div:nth-child(-2n+7), 
			&:nth-child(8) ~ div:nth-child(-2n+12) {
			  background-color: $blue-lightest-color-with-transparent;;
			}
		}

		@include media-breakpoint-down(sm) {
			min-height: 160px;

			&:nth-child(-2n+2),
			&:nth-child(2) ~ div:nth-child(-2n+3),
			&:nth-child(4) ~ div:nth-child(-2n+6) {
				background-color: $blue-lightest-color-with-transparent;;
			}
		}
	}

	a {
		width: 100%;
		text-align: center;
	}

	img {
		width: 100%;
		max-width: 160px;
	}
}