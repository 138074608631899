.about-us-desc {
	display: flex;

	@include media-breakpoint-up(lg) {
		justify-content: flex-end;
		align-items: flex-end;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
		align-items: flex-start;
	}
}