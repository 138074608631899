/*
 Home Page Preload
*/

#preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 9999;

  .bodymovin {

    @include media-breakpoint-up(lg) {
      width: 220px;
      height: 220px;
    }

    @include media-breakpoint-down(md) {
      width: 120px;
      height: 120px;
    }
  }
}