.menu {
	display: flex;
	align-items: center;
	background-color: transparent;
	outline:none;
	border:0 none;
	
	top: calc(50% - 12px);
	left: 40px;
	cursor: pointer;
	font-weight: $font-weight-bold;
	overflow: hidden;
	z-index: $zindex-navbar;

	@include media-breakpoint-up(lg) {
		position: fixed;
		top: calc(50% - 12px);
		left: 40px;

		&:hover {
			
			.menu-icon {

				&::before,
				&::after {
					content: "";
					height: 2px;
					width: 20px;
					transition: width .2s ease;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		position: absolute;
		top: 10px;
		left: 30px;
		padding: 15px 22px 15px 0px;
	}
}

.menu-icon {
	position: relative;
	width: 20px;
	height: 12px;
	overflow: hidden;

	span,
	&::before,
	&::after {
		display: block;
		background-color: $gray-dark;
		position: absolute;
		left: 0;
	}

	span {
		width: 20px;
		height: 2px;
		top: 5px;
	}

	&::before,
	&::after {
		content: "";
		height: 2px;

		@include media-breakpoint-up(lg) {
			transition: width .2s linear;
		}
	}

	&::before {
		width: 10px;
		top: 0;
	}

	&::after {
		width: 15px;
		bottom: 0;
	}

	@include media-breakpoint-up(lg) {
		margin-right: 20px;
	}
}