.space-big-title {
	display: flex;
	align-items: flex-start;

	@include media-breakpoint-up(lg) {
		justify-content: flex-end;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
		padding: 0 $container-padding;
		margin-bottom: 36px;
	}

	.display-2 {
		line-height: 1;
		
		@include media-breakpoint-up(lg) {
			flex: 0 1 480px;
		}

		@include media-breakpoint-down(md) {
			flex: 0 1 420px;
		}
	}
}