.modal-with-patern {
	background: url(/assets/img/site/patern.png) repeat 50% 50%;
}

.booking-popup {

	.booking-popup-content {
		position: relative;

		@include media-breakpoint-up(lg) {
			padding: 172px 0 180px;
		}

		@include media-breakpoint-down(md) {
			padding: 90px $container-padding 30px;
		}
	}

	.booking-title {
		line-height: 1;

		@include media-breakpoint-up(lg) {
			font-size: 92px;
			margin-bottom: 122px;
		}

		@include media-breakpoint-down(md) {
			font-size: 40px;
		}
	}

	.has-float-label {

		input[type="text"],
		input[type="email"],
		textarea,

		.select-style {

			@include media-breakpoint-up(lg) {
				margin-bottom: 42px;
			}
		}
	}
}

.close-popup {
	position: absolute;
	cursor: pointer;
	z-index: 1;
	background-color: transparent;
	outline: none;
	border:0 none;
	padding:0;

	@include media-breakpoint-up(lg) {
		top: 50px;
		right: 50px;
		width: 30px;
		height: 30px;
	}

	@include media-breakpoint-down(md) {
		width: 16px;
		height: 16px;
		top: 28px;
		left: 30px;
	}
}

.close-popup-icon {
	display: inline-block;

	@include media-breakpoint-up(lg) {
		width: 30px;
		height: 30px;
	}

	@include media-breakpoint-down(md) {
		width: 16px;
		height: 16px;
	}

	&.close-icon-black {
		fill: $gray-dark;
	}

	&.close-icon-white {
		fill: $white;
	}

	&.close-icon-black-white {

		@include media-breakpoint-up(lg) {
			fill: $gray-dark;
		}

		@include media-breakpoint-down(md) {
			fill: $white;
		}
	}
}

.table-configuration {
	font-weight: $font-weight-bold;
	
	@include media-breakpoint-up(xl) { 
		font-size: 20px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	@include media-breakpoint-up(lg) { 
		margin-bottom: 65px;
		padding-top: 30px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 26px;
		margin-bottom: 30px;
	}

	.img {
	  background-size: contain;
	  background-repeat: no-repeat;
	  mask-repeat: no-repeat;
	  mask-position: center;
	  display: inline-block;

	  @include media-breakpoint-up(lg) { 
			height: 124px;
	  	width: 124px;
		}

	  @include media-breakpoint-down(md) {
			height: 74px;
	  	width: 74px;
		}
	}

	.btn-group {	
		width: 100%;

		@include media-breakpoint-up(lg) { 
			border-bottom: 5px solid $gray-dark;
			padding-top: 32px;
			padding-bottom: 41px;
		}

		@include media-breakpoint-down(md) {
			border-bottom: 3px solid $gray-dark;
			padding-top: 30px;
			padding-bottom: 35px;
		}

		.btn {
			cursor: pointer; 
			display: inline-block;

			@include media-breakpoint-up(lg) { 
				margin: 0 17px;
			}

			@include media-breakpoint-down(md) {
				margin: 0 6px;
			}

			&:hover {
				background-color: $transparent;
			}

			&.active {
				background-color: $transparent;

				.img {
					background-color: #d01c45;
				}
			}

			.img {
				background-color: $gray-dark;
			}
		}
	}
}

.room-configuration {
	display: inline-block;
	font-weight: $font-weight-bold;
	line-height: 38px;

	@include media-breakpoint-between(lg , xl) {
		margin-right: 30px;
	} 

	@include media-breakpoint-up(xl) { 
		margin-right: 45px;
		font-size: 20px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
	}

	@include media-breakpoint-down(md) {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	&:last-child {
		margin-right: 0;

		@include media-breakpoint-down(md) {
			margin-bottom: 50px;
		}
	}

	.btn-title {
		display: inline-block;
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {
			margin-right: 24px;
		}
	}

	.btn-group {

		@include media-breakpoint-up(lg) { 
			border: 3px solid $gray-dark;
		}

		@include media-breakpoint-down(md) {
			border: 2px solid $gray-dark;
		}

		.btn {
			cursor: pointer; 
			padding: 0;
			margin: 0;
			
			&:hover,
			&.active {
				background-color: $transparent;
				opacity: 1;
			}

			span {
				color: $gray-dark;
				display: block;
				font-weight: $font-weight-bold;
				line-height: 38px;
				min-width: 56px;
			}

			input[type=radio]:checked + span {
				background-color: $gray-dark;
				color: $white;
			}
		}
	}
}