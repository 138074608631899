.table-layout {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
	}
	
	@include media-breakpoint-down(md) {
		margin-bottom: 25px;
	}
	
	thead {

		th {
			line-height: 1.2;

			@include media-breakpoint-up(xl) {
				font-size: 40px;
				padding-bottom: 38px;
				border-bottom: 10px solid $gray-dark;
				text-align: right;
			}
			
			@include media-breakpoint-down(lg) {
				font-size: 20px;
				border-bottom: 5px solid $gray-dark;
				padding-bottom: 14px;
			}
		}
	}

	tbody {

		tr {

			&:last-child {

				td {
					border-bottom: none;
				}
			}
		}

		td {
			border-bottom: 2px solid $gray-dark;
			line-height: 1.2;

			@include media-breakpoint-up(xl) {
				font-size: 20px;
				padding-top: 22px;
				padding-bottom: 20px;
			}

			@include media-breakpoint-down(xl) {
				font-size: 16px;
				width: 50%;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			
			&:first-child {
				font-weight: $font-weight-bold;
			}

			&:last-child {
				text-align: right;
			}
		}
	}

	.block-prices {
		display: inline-block;
		vertical-align: middle;

		@include media-breakpoint-down(md) {
			
			&:last-child {
				margin-left: 10px;
			}
		}
	}

	.price {
		display: block;
		font-family: $font-family-brand-mono;

		&.hyphen {
			font-family: $font-family-base;
		}

		@include media-breakpoint-up(xl) {
			font-size: 40px;

			&.hyphen {
				margin: 0 30px;
			}
		}

		@include media-breakpoint-down(lg) {
			font-size: 20px;

			&.hyphen {
				margin: 0 15px;
			}
		}
	}
}