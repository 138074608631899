.lets-talk {

	@include media-breakpoint-up(lg) { 
		display: flex;
		margin-bottom: 113px;
	}

	.display-2 {
		line-height: 1;

		@include media-breakpoint-up(xl) { 
			font-size: 92px;
		}

		@include media-breakpoint-down(xl) { 
			font-size: 40px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 16px;
		}
	}

	.w-50 {

		@include media-breakpoint-up(lg) { 
			display: flex;
			align-items: flex-end;
		}
	}
}