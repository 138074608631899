.space-desc {
	position: relative;

	@include media-breakpoint-up(lg) {
		max-width: 400px;
	}
	
	@include media-breakpoint-down(lg) {
		margin-bottom: 16px;
	}

	@include media-breakpoint-up(xl) {  
		font-size: 20px; 
		line-height: 30px;
	}

	@include media-breakpoint-down(xl) { 
		font-size: 16px;
		line-height: 1.625;
	}

	p {

		@include media-breakpoint-up(xl) {  
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(xl) { 
			margin-bottom: 26px;
		}
	}

	a {
		text-decoration: none;
		font-weight: $font-weight-bold;
	}
}