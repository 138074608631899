.top-block {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	min-height: 350px;
	background-position: center;
	background-size: cover;
	background-color: $blue;
	background-blend-mode: overlay;
	
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: url(/assets/img/site/patern.png) 0 0 repeat;
		opacity: .8;
		z-index: 1;
	}
}

.big-letter {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate3d(0px, -50%, 0);
	right: 0;
	color: $big-letter-color;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;
	mix-blend-mode: overlay;
	font-weight: bold;
	overlay: hidden;

	@include media-breakpoint-up(xl) {
		font-size: $font-size-big-letter;
	}

	@include media-breakpoint-between(md , lg) {
		font-size: 45rem;
	}

	@media screen and (max-height: 800px) {
		font-size: 40rem;
	}

	@include media-breakpoint-down(md) {
		font-size: $font-size-mobile-big-letter;
	}

	@media screen and (max-height: 550px) {
		font-size: $font-size-mobile-big-letter;
	}
}

.top-center {
	text-align: center;
	font-weight: $font-weight-bold;
	z-index: 2;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 14px;
		padding: 0 $container-padding;
		line-height: 1.45; 
	}

	@media screen and (max-height: 600px) {
		font-size: 14px;
	}

	.display-2 {

		@include media-breakpoint-down(lg) {
			font-size: 40px;
			line-height: 1;
			margin-bottom: 16px;
		}

		@media screen and (max-height: 600px) {
			font-size: 40px;
		}
	}
}