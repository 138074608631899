/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: inline-block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

/*
  * Custom style for Slick

  * Slick
  * Arrows
  * Dots
*/

/* Arrows */
.slider-button-block {
  display: flex;
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 10;
  cursor: pointer;

  &:hover, 
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }

  &.slick-disabled {
    opacity: $slick-opacity-not-active;
    cursor: auto;
    pointer-events: none;
  }

  span {
    position: relative;
    float: left;
    width: 8px;
    height: 14px;
    
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: $gray-dark;
    }
  }
}

.prev {
  margin-right: 8px;

  span {

    &::before,
    &::after {
      left: 0;
    }

    &::before {
      top: 3px;
      transform: rotate(-45deg);
    }

    &::after {
      top: 9px;
      transform: rotate(45deg);
    }
  }
}

.next {

  span {

    &::before,
    &::after {
      right: 0;
    }

    &::before {
      top: 3px;
      transform: rotate(45deg);
    }

    &::after {
      top: 9px;
      transform: rotate(-45deg);
    }
  }
}

/* Dots */
.slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    float: left;
    font-family: $font-family-brand-mono;
    background: $transparent;
    cursor: pointer;

    @include media-breakpoint-up(xl) {
      font-size: 40px;
      padding-bottom: 5px;
      margin-right: 30px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 20px;
      padding-bottom: 3px;
      margin-right: 28px;
    }
  
    &:last-child {
      margin-right: 0px; 
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $transparent;
      transition: width .2s linear;
      width: 0;

      @include media-breakpoint-up(xl) {
        height: 5px;
      }

      @include media-breakpoint-down(lg) {
        height: 3px;
      }
    }

    &.slick-active {
      
      &::after {
        background-color: $brand-primary;
        width: 100%;
        transition: width .2s linear;
      }
    }
  }
}

/* Home sliders custom styles */
.home-slider-block-big-img,
.small-img-block,
.controls-text-container {
  overflow: hidden;
}

.home-slider-block-big-img {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
    align-items: flex-end;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
    align-items: flex-start;
  }
}

.slider-desc {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}

.slider-desc-text {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(xl) {
    margin-right: 80px;
    font-size: 20px;
    line-height: 30px;
  }

  @include media-breakpoint-down(lg) {
    margin-right: 30px;
    font-size: 16px;
    line-height: 26px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
    flex: 0 1 403px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 $container-padding 26px;
    margin-right: 0;
    flex: 0 1 480px;
  }

  .link-with-border-red {
    font-size: $font-size-base;
    line-height: 24px;
  }
}

.slider-desc-duplicate {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  font-size: $font-size-base;
  padding: 0 $container-padding;
}

.desc-duplicate-text {
  flex: 0 1 420px;
}

.slider-controls-panel {
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    margin-top: -140px;
    padding: 55px 50px;
    background-color: $white;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: space-between;
    padding: 26px $container-padding 22px;
    min-height: 154px;
  }
}

.controls-text-container {
  height: 24px;
  width: 100%;
  text-align: center;
}

.controls-panel-text {
  line-height: 24px;
  height: 24px;
  display: block;
  font-size: $font-size-base;
  white-space: nowrap;
}

.small-img-block {
  display: flex;
  align-items: flex-start;
  margin-top: -140px;
  z-index: 0;
}

.rent {
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-down(md) {
    padding: 0 $container-padding 45px;
  }
}

.slider-numbers {
  display: flex;
  position: relative;
  text-align: center;
  transition: 300ms;
  font-family: $font-family-brand-mono;
  font-weight: $font-weight-regular;
  font-size: $font-size-base;
  z-index: 10;

  .delimiter {
    margin: 0 10px;
  }
}

/* Spaces sliders custom styles */
.spaces-big-img-block {
  z-index: 2; 
}

.space-flex-end {
  width: 100%;

  @include media-breakpoint-up(xl) {
    padding: 37px 0 80px 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 20px 80px 37px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 20px 80px 37px 30px;
  }
}

/* Slider pagination */
.slider-meeting-room-pagination,
.slider-conference-room-pagination {

  @include media-breakpoint-up(lg) {
    flex: 0 1 480px;
  }

  @include media-breakpoint-down(md) {
    flex: 0 1 365px;
  }
}

.space-info-meeting,
.space-info-conference {
  display: flex;
  width: 100%;

  @include media-breakpoint-up(xl) {
    align-items: flex-start;
    justify-content: center;
  }
  
  @include media-breakpoint-down(lg) {
    align-items: center;
    justify-content: flex-start;
  }

  .space-content {

    @include media-breakpoint-up(xl) {
      display: block;
      width: 703px;
    }
  }

  .space-desc {
    position: relative;

    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      max-width: 480px;
    }

    p {

      @include media-breakpoint-up(xl) {
        max-width: 400px;
      }
    }
  }
}

.space-info-block {
  position: relative;

  @include media-breakpoint-up(xl) {
    padding: 182px 0 140px;
  }

  @include media-breakpoint-down(lg) {
    padding: 90px 0 140px;
  }

  @include media-breakpoint-up(lg) { 
    z-index: 1;
  }
  
  @include media-breakpoint-down(md) {
    max-width: 480px;
    margin: -25px auto 0;
    padding: 0px $container-padding;
    z-index: 3;
  }
}