.pricing {
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-bottom: 180px;
	}

	@include media-breakpoint-down(md) {
		margin: 0 auto;
		padding: 0px $container-padding $container-padding;
	}

	.big-text {

		@include media-breakpoint-up(lg) {
			width: 100%;
			text-align: center;
			line-height: 1.2;
			top: 20%;
			left: 50%;
			overflow: hidden;
			transform: translate3d(-50%, 0, 0);
		}

		@include media-breakpoint-down(md) {
			left: calc(50% - 250px);
			transform: rotate(270deg) translate3d(-45%, 0, 0);
		}
	}

	.w-50 {

		@include media-breakpoint-up(lg) {  
			width: calc(100% / 2 - 60px);
		}

		@include media-breakpoint-down(md) {  
			width: 100%;
		}

		&:first-child {

			@include media-breakpoint-up(lg) {  
				margin-right: 120px;
			}
		}
	}

	.table-columns {
		position: relative;

		@include media-breakpoint-up(lg) {
			padding-bottom: 227px;
		}
		
		@include media-breakpoint-down(md) {
			padding-bottom: 105px;
		}

		&:after {
			display: block;
			position: absolute;
			left: 50%;
			content: "";
			width: 1px;
			background-color: $gray-dark;
			transform: translate3d(-50%, 0, 0);

			@include media-breakpoint-up(lg) {
				height: 80px;
				bottom: 140px;
			}

			@include media-breakpoint-down(md) {
				height: 50px;
				bottom: 50px;
			}
		}
	}
}