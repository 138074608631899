.hi-there-section {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	&.hi-there-section-bg-hidden-lg-up {

		@include media-breakpoint-up(lg) {
			position: relative;
			height: 720px;
			z-index: 0;
		}

		@include media-breakpoint-down(md) {
			background-image: url(/assets/img/site/get-in-touch.jpg);
			background-position: center;
		  background-size: cover;
		  background-color: #b0d2e6;
		  background-blend-mode: overlay;
		  height: 100vh;
		}

		.get-in-touch {
			
			@include media-breakpoint-up(lg) {
			
			}

			&:hover {

				@include media-breakpoint-up(lg) {
					color: $brand-primary;
				}
			}
		}

		&::before,
	  &::after {

	  	@include media-breakpoint-up(lg) {
				display: none;
			}

			@include media-breakpoint-down(md) {
				display: block;
			}
	  }
	}

	&.hi-there-section-bg-hidden-md-down {
		
		@include media-breakpoint-up(lg) {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			height: 720px;
			z-index: 0;
			background-image: url(/assets/img/site/get-in-touch.jpg);
			background-position: center;
		  background-size: cover;
		  background-color: #b0d2e6;
		  background-blend-mode: overlay;
		}
	}

	&::before,
  &::after {
		content: "";
		position: absolute;
  }

  &::before {
  	
  	@include media-breakpoint-down(md) {
  		top: -30px;
			left: 50%;
			width: 1px;
			height: 60px;
			background-color: $gray-dark;
			z-index: 2;
  	}
  }

	&::after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(/assets/img/site/patern.png) 0 0 repeat;
    opacity: .8;
    z-index: 1;
	}

	.rotated-page-name {

		@include media-breakpoint-down(lg) {
			font-size: 20px;
			padding-left: 46px;
			right: 53px;
		}

		&::before {

			@include media-breakpoint-down(lg) {
				height: 5px;
				width: 30px;
				top: calc(50% - 2px);
			}
		}
	}

	.social {
		position: absolute;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		bottom: 47px;
		justify-content: center;
	}
}