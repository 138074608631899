.events-block {

	@include media-breakpoint-up(lg) {
		padding: 180px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 105px 0 32px;
	}	
}

.events-left {
	display: flex;
	align-self: flex-end;
	flex-wrap: wrap;

	@include media-breakpoint-up(xl) {
		padding-right: 80px;
	}

	@include media-breakpoint-down(lg) {
		padding-right: 30px
	}	

	@include media-breakpoint-down(md) {
		padding-right: $container-padding;
		padding-left: $container-padding;
		justify-content: center;
		order: 2;
	}	
	
	.btn {
		@include media-breakpoint-up(lg) {
			flex: 0 1 480px;
		}

		@include media-breakpoint-down(md) {
			flex: 0 1 420px;
		}
	}
}

.events-desc {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	@include media-breakpoint-up(xl) {
		font-size: 30px;
		padding-bottom: 120px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		padding-bottom: 26px;
	}

	@include media-breakpoint-up(lg) {
		padding-left: 110px;
		justify-content: flex-end;
		align-items: flex-end;
	}

	@include media-breakpoint-down(md) {
		padding-top: 45px;
		justify-content: center;
		align-items: flex-start;
		text-align: center;
	}

	&::before {
		content: "";
		position: absolute;
		right: calc(100% - 80px);
		width: 80px;
		height: 1px;
		background-color: $gray-dark;
		
		@include media-breakpoint-up(xl) {
			top: 157px;
		}

		@include media-breakpoint-down(lg) {
			display: block;
			top: 88px;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.events-title {
	width: 100%;

	@include media-breakpoint-up(xl) {
		margin-bottom: 48px;
	}

	@include media-breakpoint-down(lg) {
		padding: 0 $container-padding 40px;
		margin-bottom: 0;
	}
}

.events-img {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	order: 1;
	z-index: 2;

	@include media-breakpoint-down(lg) {
		text-align: center;
	}
}

.events-desc-content {

	@include media-breakpoint-up(lg) {
		flex: 0 1 405px;
	}

	@include media-breakpoint-down(md) {
		flex: 0 1 420px;
	}
}