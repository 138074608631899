@import "fonts";
@import "variables";

/* 
	* Vendors
*/

/* 
	* Core variables and mixins 
*/
//@import "bootstrap/scss/custom";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* 
	* Reset and dependencies 
*/
@import "bootstrap/scss/normalize";
@import "bootstrap/scss/print";

/* 
	* Core CSS 
*/
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";

/* Components bootstrap */
//@import "bootstrap/scss/animation";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/tags";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/responsive-embed";
//@import "bootstrap/scss/close";

// Components w/ JavaScript
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";

// Utility classes
@import "bootstrap/scss/utilities";

/* 
	* App
*/
@import "normalize/helpers";

/* 
	* Components 
	
	* Common
	* Home
	* A.G.B
	* Impressum
	* About-us
	* Community
	* Post-events-survey
	* Post-events-survey-v2
	* Event-detailed
	* Event-spaces
	* Workspace
*/

/* Common components */
@import "components/animation";
@import "components/logo";
@import "components/languages";
@import "components/social";
@import "components/menu";
@import "components/nav";
@import "components/scroll-block";
@import "components/custom-input";
@import "components/buttons";
@import "components/btn-group";
@import "components/big-text";
@import "components/about-spaces";
@import "components/space-item";
@import "components/space-img";
@import "components/space-info";
@import "components/space-content";
@import "components/space-name";
@import "components/space-desc";
@import "components/space-negative-img-small";
@import "components/space-img-small";
@import "components/pricing";
@import "components/table-layout";
@import "components/rotate-device-message";
@import "components/rotated-page-name";
@import "components/submit-success";
@import "components/preloader";
@import "components/top-block";
@import "components/slick-slider";
@import "components/link-with-border";
@import "components/menu-header";

/* Home page components */
@import "components/about-us-section";
@import "components/about-us-desc";
@import "components/about-us-text";
@import "components/about-us-img";
@import "components/community-section";
@import "components/community-left";
@import "components/community-left-desc";
@import "components/community-left-img";
@import "components/community-right";
@import "components/community-right-desc";
@import "components/events-section";
@import "components/partners-section";
@import "components/hi-there-section";
@import "components/hi-there-block";
@import "components/home-about-us";
@import "components/img-with-letter";
@import "components/video";
@import "components/welcome";
@import "components/welcome-top";
@import "components/welcome-bottom";
@import "components/welcome-desc";
@import "components/welcome-text";
@import "components/workspace-section";
@import "components/get-in-touch";

/* A.G.B page components */
@import "components/personal-info";

/* Impressum page components */
@import "components/impressum";

/* About-us page components */
@import "components/about-top-block";
@import "components/about-team";
@import "components/about-team-desc";
@import "components/about-theme";
@import "components/team-block";
@import "components/team-list";

/* Community page components*/
@import "components/community-top-block";
@import "components/community-challenge-meeting";
@import "components/community-our-spaces";
@import "components/community-fun-activities";
@import "components/community-our-family";
@import "components/community-pride-ourselves";
@import "components/community-partners";

/* Post-events-survey page components */
@import "components/post-event";

/* Post-event-survey-v2 page components */
@import "components/post-event-survey-v2";
@import "components/decimal-zero-list";
@import "components/decimal-title-h2";
@import "components/lower-alpha-list";
@import "components/lower-title-h5";
@import "components/fixed-questions";
@import "components/nav-questions";
@import "components/progress-block";
@import "components/progress-state";
@import "components/progress-custom";

/* Event-detailed page components */
@import "components/event-detailed";
@import "components/event-date-and-time";
@import "components/event-info";
@import "components/opener-berlin";
@import "components/share-event";
@import "components/upcoming-events";

/* Event-spaces page components */
@import "components/spaces-top-block";
@import "components/events-block";
@import "components/spaces-title";
@import "components/space-big-title";
@import "components/block-with-bottom-border";
@import "components/with-separator";
@import "components/space-flex-end";

/* Workspace page components */
@import "components/workspace-top-block";
@import "components/light-blue-bar-right";
@import "components/border-rose-virtual-offices";

/* Contact us */
@import "components/contact-top-block";
@import "components/contact-form";
@import "components/contact-get-mail";
@import "components/contact-get-notified";
@import "components/contact-lets-talk";
@import "components/contact-map";
@import "components/custom-form";

/* Booking Popup */
@import "components/booking-popup";

/* Calendar Popup */
@import "components/calendar-popup";

/* Video Popup */
@import "components/video-popup";

/* Booking Form*/
@import "components/booking-form";

/* Event Reservation Calendar */
@import "erc/erc";

@import "smooth-scrollbar/dist/smooth-scrollbar";