.events-section {
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-bottom: 0px;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 193px;
	}
}