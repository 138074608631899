.video-popup {
  background-color: $black;
  padding: 0 !important;
  overflow: hidden !important;
  min-height: 0 !important;
  height: 100%;

	.videoWrapper {
    display: flex;
    align-items: center;
    justify-center: center;
    height: 100%;

  	@include media-breakpoint-up(lg) {
  		position: relative;
  	}

	  iframe {
      margin: 0 auto;
      display: block;
      width: 100%;
		}
	}
}