.languages {
	position: fixed;
	display: flex;
	z-index: $zindex-navbar;

	@include media-breakpoint-up(lg) {
		top: 35px;
		right: 40px;
	}

	@include media-breakpoint-down(md) {
		top: 27px;
		right: 30px;
	}

	@media screen and (max-height: 750px) {
		top: 27px;
		right: 30px;
	}

	a {
		margin-right: $spacer * 1.75;
		font-weight: $font-weight-bold;
		color: $gray-dark;
		line-height: 1;

		&:last-child {
			margin-right: 0;
		}
	}
}