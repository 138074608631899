.about-us-section {
	position: relative;

	@include media-breakpoint-up(xl) {
		padding-bottom: 180px;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 253px;
		padding-bottom: 80px;
	}

	.bg-cube,
	.bg-primary-cube {
		display: block;
		position: absolute;
	}

	.bg-primary-cube {
		background-color: $primary-lightest-color-with-transparent;
		left: 25%;

		@include media-breakpoint-up(xl) {
			top: 10%;
			width: 12.5%;
			height: 90%;
		}

		@include media-breakpoint-down(lg) {
			top: 145px;
			width: 50%;
			height: calc(100% - 145px);
		}
	}

	.bg-cube {
		background-color: $blue-lightest-color-with-transparent;
		width: 25%;
		right: 0;

		@include media-breakpoint-up(xl) {
			top: 10%;
			height: 80%;
		}
	}

	.big-text {
		position: absolute;
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			top: 145px;
			left: 51.5625%;
		}

		@include media-breakpoint-down(md) {
			top: 100px;
			right: 50px;
			left: auto;
		}
	}
}