.personal-info {
	
	@include media-breakpoint-up(lg) {
		padding: 166px 0 141px;
		font-size: 20px;
	}

	@include media-breakpoint-down(md) {
		padding: 90px $container-padding 12px;
		font-size: $font-size-base;
	}

	.display-2 {
		
		@include media-breakpoint-up(lg) {
			margin-bottom: 150px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 32px;
		}
	}
}

.personal-paragraph {

	p {
		
		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 28px;
		}
	}
}

.personal-title {

	@include media-breakpoint-up(lg) {
		margin-bottom: 31px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 28px;
	}
}

.personal-title {
	margin-bottom: 30px;
	line-height: 1.5;
}