.about-us-text {

	@include media-breakpoint-up(xl) {
		font-size: 20px;
		line-height: 30px;
		padding: 0 80px 129px 30px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 26px;
		padding: 45px $container-padding 0;
	}

	@include media-breakpoint-up(lg) {
		flex: 0 1 510px;
	}

	@include media-breakpoint-down(md) {
		flex: 0 1 480px;
	}

	p {

		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}
	}

	.hidden-md-down {

		p {
			margin-bottom: 1rem;
		}
	}

	.link-with-border-red {
		font-size: 16px;
		line-height: 24px;
	}
}