.impressum {

	@include media-breakpoint-up(lg) {
		padding: 180px 0;
		font-size: 20px;
		line-height: 30px;
	}

	@include media-breakpoint-down(md) {
		padding: 90px $container-padding 12px;
		font-size: $font-size-base;
	}

	p {

		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 28px;
		}
	}

	span {
		display: block;
	}
}