.hi-there-block {
	display: block;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 2;

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding;
	}
}