.event-detailed {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		background-color: $blue-lightest-color-with-transparent;
		z-index: $z-index-negative;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 140px;
		padding-bottom: 140px;

		&::before {
			display: block;
			top: 0;
			right: 0;
			width: 50%;
			height: 14.7288%;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 72px;
		padding-bottom: 30px;

		&::before {
			display: none;
		}
	}
}

.event-top-img {

	img {
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 67px;
	}
}

.event-detailed-left {

	@include media-breakpoint-up(lg) {
		padding-top: 11px;
		margin-bottom: 165px;

		.event-date-and-time {
			margin-bottom: 40px;
		}
	}
}