.progress-block {
	padding-top: 5px;

	@include media-breakpoint-up(lg) {
		width: calc(100% - 6px);
	}

	@include media-breakpoint-down(md) {
		width: calc(100% - 4px);
	}
}