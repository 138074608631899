.lower-title-h5 {
	margin-bottom: 0;

	&::before {
		counter-increment: letter;
		content: counter(letter, lower-alpha) ".";
		margin-right: 2px;
	}

	@include media-breakpoint-down(md) {
		font-size: 0.875rem;
	}
}