.about-spaces {
	position: relative;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		padding-bottom: 171px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 80px;
	}

	&.about-spaces-with-padding {
		padding-top: 110px;
	}

	.title-h3 {
		line-height: 1.2;
		text-align: center;
		position: relative;

		&::before {

			@include media-breakpoint-down(md) {
				content: "";
				display: block;
				position: absolute;
				top: -126px;
				left: 50%;
				transform: translateX(-50%);
				width: 1px;
				height: 50px;
				background-color: $gray-dark;
			}
		}

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}

		@include media-breakpoint-up(lg) {  
			padding: 172px 0 160px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 44px;	
		}
	}
}