.rotate-device-message {
	display: none;
}


.is-mobile-landscape{
	.rotate-device-message {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url("/assets/img/site/patern.png") 50% repeat;
		text-align: center;
		font-size: 14px;
		font-weight: $font-weight-bold;
		line-height: 20px;
		z-index: 9999;

		img{
			margin-bottom: 26px;
			width: 28px;
		}
	}
}