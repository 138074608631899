.get-notified {
	background: transparent url('/assets/img/site/get-notified.jpg') 50% / cover no-repeat;

	@include media-breakpoint-up(md) {
		padding-top: 173px;
	}

	@include media-breakpoint-down(md) {
		padding: 76px 30px 0px;
	}

	.col-lg-4.offset-lg-2 {

		@include media-breakpoint-up(lg) { 
			display: flex;
			margin-bottom: 133px;
		}

		@include media-breakpoint-down(md) { 
			margin-bottom: 70px;
		}
	}

	.display-2 {
		line-height: 1;

		@include media-breakpoint-down(lg) { 
			margin-bottom: 17px;
			font-size: 40px;
		}

		@include media-breakpoint-up(xl) { 
			font-size: 92px;
		}
	}
	
	.w-50 {
		display: flex;
		align-items: flex-end;
	}
}