.space-item {
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-top: 180px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}
}

.space-item-with-horizontal-line {
	position: relative;

	@include media-breakpoint-down(md) {
		padding-top: 110px;
	}
	
	&::before {
		content: "";
		position: absolute;
		left: 50%;
		width: 1px;
		background-color: $gray-dark;
		display: block;

		@include media-breakpoint-up(lg) {
			height: 80px;
			top: -40px;
		}

		@include media-breakpoint-down(md) {
			height: 60px;
			top: -30px;
		}
	}
}

.light-primary-bg,
.light-blue-bg {
	position: absolute;
	display: block;
	width: 50%;
	backface-visibility: hidden;
	z-index: -1;
}
	
.light-space-bar-primary {
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		padding-top: 175px;
		padding-bottom: 180px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 78px;
		padding-bottom: 80px;
	}
	
	.light-primary-bg {
		background-color: $primary-lightest-color-with-transparent;
		top: 0;

		@include media-breakpoint-up(lg) {
			left: 37.5%;
			height: 60.870%;
		}

		@include media-breakpoint-down(md) {
			right: 0;
			height: 92.75%;
		}
	}
}

.light-space-bar-blue {
	margin-bottom: 0;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding: 175px 0 180px;
	}

	@include media-breakpoint-down(md) {
		padding: 106px 0 59px;
	}

	.light-blue-bg {
		background-color: $blue-lightest-color-with-transparent;
		height: 100%;
		top: 0px;

		@include media-breakpoint-up(lg) {
			left: 37.5%;
		}

		@include media-breakpoint-down(md) {
			left: 26.5%;
		}
	}
}