.calendar-popup {

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding;
	}

	.calendar-top {
		position: relative;

		@include media-breakpoint-up(lg) {
			text-align: center;
			padding-top: 74px;
			padding-bottom: 78px;
		}

		@include media-breakpoint-down(md) {
			background-color: $gray-dark;
			color: $white;
			margin: 0 -30px;
			text-align: right;
			padding: 25px $container-padding;
		}

		h2 {
			margin-bottom: 0;

			@include media-breakpoint-down(md) {
				font-size: 20px;
			}
		}
	}

	.calendar-body {

		@include media-breakpoint-up(lg) {
			padding-bottom: 80px;
		}

		@include media-breakpoint-down(md) {
			padding-bottom: 30px;
		}
	}
}

.calendar-th {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 10px solid $gray-dark;
	padding-top: 72px;
	padding-bottom: 70px;

	@include media-breakpoint-down(md) {
		display: none;	
	}

	.item {
		width: calc(100% / 7);
		padding-right: 30px;
		font-size: 40px;
		font-weight: bold;
		line-height: 1.2;
	}
}

.calendar-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	@include media-breakpoint-down(md) {
		padding-top: 77px;
	}

	.item {
		padding-bottom: 52px;

		@include media-breakpoint-up(lg) {
			width: calc(100% / 7);
			padding-right: 30px;
		}

		@include media-breakpoint-down(md) {
			width: calc(100% / 2);
			display: none;
		}

		&:nth-child(-n+8) {

			@include media-breakpoint-down(md) {
    		display: block;
    	}
		}
	}

	h5 {
		line-height: 1.2;

		@include media-breakpoint-up(lg) {
			margin-bottom: 11px;
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom: 6px;
		}
	}

	.placement {
		line-height: 1.2;
		margin-bottom: 21px;
	}

	.is-past {
		color: rgba(31, 31, 31, .5);
	}

	.is-active {
		color: $brand-primary;
	}

	.date {
		font-family: $font-family-brand-mono;
		line-height: 1.2;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include media-breakpoint-up(lg) {
			font-size: 40px;
			margin-bottom: 34px;
		}
		
		@include media-breakpoint-down(md) {	
			font-size: 20px;
			margin-bottom: 19px;
		}

		sup {
			font-size: 58%;

			@include media-breakpoint-up(lg) {
				top: 16px;
			}

			@include media-breakpoint-down(md) {	
				top: 8px;
			}
		}
	}
}