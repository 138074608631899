.post-event {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
}

.post-event-logo {
	display: block;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	@include media-breakpoint-up(md) {
		position: absolute;
		top: 80px;
		left: calc(50% - 90px);
	}

	@include media-breakpoint-down(sm) {
		width: 110px;
		height: 22px;
		left: calc(50% - 55px);
	}
}

.post-event-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 100vh;

	strong {
		display: block;
	}

	@include media-breakpoint-up(md) {
		justify-content: center;
		padding: 116px 0 80px;
		width: 480px;
		min-height: 714px;

		.display-2 {
			margin-bottom: 21px;
		}

		strong {
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 70px;
		}
	}

	@include media-breakpoint-down(sm) {
		justify-content: space-between;
		padding: 30px 0;
		width: 100%;
		min-height: 568px;

		.display-2 {
			margin-bottom: 16px;
		}

		strong {
			line-height: 20px;
			font-size: 14px;
		}

		.btn {
			padding: 1.25rem 1rem;
			font-size: 0.875rem;
		}
	}
}