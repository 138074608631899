.decimal-title-h2 {
	position: relative;
	margin-bottom: 0;

	&::before {
		counter-increment: number;
		content: counter(number, decimal-leading-zero) ".";
		display: inline-block;
		font-family: $font-family-brand-mono;
	}

	@include media-breakpoint-up(lg) {
		line-height: 48px;
		font-size: 40px;

		&::before {
			position: absolute;
			top: 0;
			left: -92px;
			font-size: 40px;
			line-height: 48px;
		}
	}

	@include media-breakpoint-down(md) {
		line-height: 24px;
		font-size: 20px;

		&::before {
			line-height: 24px;
			font-size: 20px;
		}
	}
}
