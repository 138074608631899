.mobile-header {

	@include media-breakpoint-down(md) {
		position: absolute;
		height: 72px;
		width: 100%;
		transform: translate3d(0, 0, 0);
		z-index: 1000;
	}

	&.mobile-header-top {

		@include media-breakpoint-down(md) {
			transform: translate3d(0, 0, 0);
    	top: 0;
  	}
	}

	&.mobile-header-top {

		&.mobile-header-not-bottom {

			&.mobile-header-pinned {

				@include media-breakpoint-down(md) {
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}

	&.mobile-header-not-top {

		@include media-breakpoint-down(md) {
			position: fixed;
    	top: -72px;
			transition: .35s transform ease;
  	}

  	&.mobile-header-unpinned {

			@include media-breakpoint-down(md) {
				transform: translate3d(0, 0, 0);
			}
		}

		&.mobile-header-pinned {

			@include media-breakpoint-down(md) {
				background: url(/assets/img/site/patern.png) repeat center;
			}
		}
	}

	&.mobile-header-not-bottom {

		@include media-breakpoint-down(md) {
			transform: translate3d(0, 0, 0);
		}

		&.mobile-header-pinned {

			@include media-breakpoint-down(md) {
				transform: translate3d(0, 72px, 0);
			}
		}
	}
}