.workspace-section {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-top: 180px;
    margin-bottom: 74px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 275px;
  }

	.ws-blue {
		position: absolute;
		right: 0;
		top: 0;
		background-color: $blue-lightest-color-with-transparent;

		@include media-breakpoint-up(lg) {
			top: 40px;
			width: 37.5%;
			height: 100%;
		}

		@include media-breakpoint-down(md) {
			top: 156px;
			width: 50%;
			height: 28.043%;
		}
	}

	.big-text {
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			left: 279px;
			bottom: 276px;
		}

		@include media-breakpoint-down(md) {
			top: 120px;
			right: 50px;
			bottom: auto;
		}
	}
}