.social {
	display: flex;
	position: fixed;
	bottom: 35px;
	right: 40px;
	z-index: $zindex-navbar;

	@media screen and (max-height: 750px) {
		bottom: 27px;
	}

	a {
		display: block;
		color: $gray-dark;
		font-weight: 700;
		margin-right: $spacer * 1.75;
		line-height: 1;

		&:last-child {
			margin-right: 0;
		}

		&:first-letter {
			text-transform: uppercase;
		}
	}
}