.progress-state {
	font-family: $font-family-brand-mono;
	font-weight: $font-weight-bold;

	@include media-breakpoint-up(lg) {
		font-size: 20px;
	}

	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
}