.rotated-page-name {
	position: absolute;
	margin: 0;
	line-height: 1;
	font-size: 20px;
	z-index: 2;

	@include media-breakpoint-up(lg) {
		top: 51.5%;
		right: 63px;
		padding-left: 70px;
		transform-origin: 100% 50%;
		transform: rotate(-90deg) translate3d(50%, 50%, 0);
	}

	@include media-breakpoint-down(md) {
		top: 80px;
		right: calc(50% + 10px);
		padding-left: 42px;
		transform-origin: 100% 0;
		transform: rotate(-90deg) translate3d(0, 0, 0);
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		background-color: $gray-dark;

		@include media-breakpoint-up(lg) {
			width: 50px;
			height: 2px;
			top: calc(50% - 1px);
		}

		@include media-breakpoint-down(md) {
			width: 30px;
			height: 5px;
			top: calc(50% - 2px);
		}
	}
}

.move {

	@include media-breakpoint-up(lg) {
		position: fixed;
		top: 50%;
		right: 47px;
		height: 400px;
		transform: translate3d(0px, -50%, 0px);
		z-index: 2;
	}
}

.move-content {

	@include media-breakpoint-up(lg) {
	  transition: opacity .8s cubic-bezier(0, 1, 0.5, 1), transform .8s cubic-bezier(0, 1, 0.5, 1);
		height: 2400px;
	}
  
	h2 {

		@include media-breakpoint-up(lg) {
	    opacity: 0;
	    height: 400px;
	    transition: opacity .8s cubic-bezier(0, 1, 0.5, 1), transform .8s cubic-bezier(0, 1, 0.5, 1);
	    transform: rotate(-90deg);

			&.is-active {
				opacity: 1;
			}
		}

		span {

			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 43%;
				right: 50%;
				padding-left: 70px;
				line-height: 1;
				font-size: 1.25rem;
				white-space: nowrap;
				transform: translate3d(50%, 50%, 0);

				&::before {

					@include media-breakpoint-up(lg) {
						display: block;
						position: absolute;
						left: 0;
						top: calc(50% - 1px);
						content: "";
						width: 50px;
						height: 2px;
						background-color: $gray-dark;
					}
				}
			}
		}	
	}
}