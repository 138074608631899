.get-in-touch {
	position: relative;
	overflow: hidden;
	display: inline-block;
	transition: color .6s cubic-bezier(.4,0,.2,1);

	@include media-breakpoint-up(lg) {
		margin-bottom: 25px;
	}

	&:hover {

		@include media-breakpoint-up(lg) {
			color: $brand-primary;
			transition: color .6s cubic-bezier(.4,0,.2,1);

			+ .we-talk {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, -100%, 0);
				transition:  transform .6s, opacity .6s, visibility .6s;
			}
		}
		
		&::before {

			@include media-breakpoint-up(lg) {
				transform: translate3d(0, 0, 0);
				transition: transform .3s cubic-bezier(.4,0,.2,1);
			}
		}
	}

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		left: 0;
		background-color: $brand-primary;
		display: block;
		transform: translate3d(-100%, 0, 0);
		transition: transform .3s cubic-bezier(.4,0,.2,1);

		@include media-breakpoint-up(xl) {
			top: calc(50% - 20px);
			height: 30px;
		}

		@include media-breakpoint-down(lg) {
			height: 10px;
			top: calc(50% - 7px);
		}
	}
}

.we-talk {
	display: block;
	font-weight: $font-weight-bold;
	color: $gray-dark;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	@include media-breakpoint-up(lg) {
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0, 0, 0);
		transition:  transform .6s, opacity .6s, visibility .6s;
	}
}