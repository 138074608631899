.light-blue-bar-right {

	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		background-color: $blue-lightest-color-with-transparent;

		@include media-breakpoint-up(lg) {
			right: -16.66%;
			width: 33.33%;
		}

		@include media-breakpoint-down(md) {
			right: 0;
			width: 50%;
		}
	}
}