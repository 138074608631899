.opener-berlin {

	h2 {
		line-height: 1.2;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 5px;
		margin-bottom: 165px;

		h2 {
			margin-bottom: 38px;
		}
	}

	@include media-breakpoint-down(md) {
		padding-right: $container-padding;
		padding-left: $container-padding;
		padding-top: 50px;
		font-size: 16px;

		.event-date-and-time {
			margin-bottom: 12px;
		}

		h2 {
			border-top: 5px solid $gray-dark;
			font-size: 20px;
			margin-bottom: 0;
			padding: 47px 0 39px;
		}
	}
}

.opener-content {
	position: relative;

	a {
		text-decoration: underline;
	}

	@include media-breakpoint-up(lg) {
		font-size: 20px;
		padding: 52px 0 35px;
		border-bottom: 2px solid $gray-dark;

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 80px;
			height: 10px;
			background-color: $gray-dark;
		}

		h5,
		p {
			margin-bottom: 35px;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: 16px;
		padding: 0 0 16px;

		p {
			margin-bottom: 28px;
		}
	}
}