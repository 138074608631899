.welcome-top {
	display: flex;
	align-items: center;
	position: relative;
	height: 100vh;

	@include media-breakpoint-up(lg) {
		padding: 110px 0 130px;
	}

	@include media-breakpoint-down(md) {
		padding: 145px $container-padding 46px;
	}
}