.map-wrapper {
	@include media-breakpoint-down(md) {
		margin-bottom: 76px;
	}

	@include media-breakpoint-up(lg) {
		margin-top: -250px;
		margin-bottom: 171px;
	}
}

#map {
	@include media-breakpoint-down(md) {
		height: 220px;
	}

	@include media-breakpoint-up(md) {
  	height: 500px;
  }
}