.modal {

	&.fade {
		
		.nav,
		.booking-popup-content,
		.close,
		.close-popup,
		.calendar-top,
		.calendar-body,
		.videoWrapper,
		.submit-success {
			opacity: 0;
			transition: opacity .3s cubic-bezier(0.26, 1.48, 0.47, 0.96);
		}

		.nav-menu {

			@include media-breakpoint-up(lg) {
				visibility: hidden;
				opacity: 0;
				transform: translate3d(10%, 0, 0);
			}
		}
	}

	&.in {
		
		.nav,
		.booking-popup-content,
		.close,
		.close-popup,
		.calendar-top,
		.calendar-body,
		.videoWrapper,
		.submit-success  {
			opacity: 1;
			transition: opacity .3s cubic-bezier(0.26, 1.48, 0.47, 0.96);
		}

		.nav-menu {

			@include media-breakpoint-up(lg) {
				visibility: visible;
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition: transform .5s, opacity .5s, visibility .5s;
			}
		}
	}

	.nav {
		position: relative;
		display: flex;
		width: 100%;
		height: 100vh;
	}
}

.close {
	position: absolute;
	z-index: $zindex-close;
	background-color: transparent;
	outline:none;
	border:0 none;
	cursor: pointer;

	@include media-breakpoint-up(lg) {
		top: calc(50% - 12px);
		left: 40px;
	}

	@include media-breakpoint-down(md) {
		top: 20px;
		left: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
	}

	span {
		position: relative;
		cursor: pointer;
		display: flex;
		align-items: center;

		@include media-breakpoint-up(lg) {			
			padding-left: 40px;
		}

		@include media-breakpoint-down(md) {
			justify-content: center;
			padding: 16px;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 21px;
			height: 2px;
			display: block;
			background-color: $gray-dark;

			@include media-breakpoint-up(lg) {
				top: 11px;
				left: -3px;
			}

			@include media-breakpoint-down(md) {
				top: 15px;
				left: 6px;
			}
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

.nav-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;

	picture {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
   	transition: opacity .3s ease-in-out;

		&.is-visible {
   		transition: opacity .2s ease-in-out;
   		opacity: 1
		}

		&:before {
		  position: fixed;
		  left: 0;
		  bottom: 0;
		  content: "";
		  width: 100%;
		  height: 100%;
		  background-color: #b0d2e6;
		}

		&:after {
			background: url(/assets/img/site/patern.png) 0 0 repeat;
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			position: fixed;
			z-index: 0;
			opacity: .8;
		}

		img {
			mix-blend-mode: overlay;
			object-fit:cover;
			width:100%;
			height:100%;
		}
	}
}

.nav-menu	{
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding;
	}

	a {
		display: block;
		flex: 0 1 auto;
		position: relative;
		text-decoration: none;
		color: $gray-dark;
		user-select: none;
		font-weight: $display2-weight;
		overflow: hidden;
		transition: color .2s ease-in-out;

		@include media-breakpoint-up(xl) {
			font-size: $display2-size;
			line-height: 1;
		}

		@include media-breakpoint-down(lg) {
			font-size: 60px;
			line-height: 1;
		}

		@media screen and (max-height: 800px) {
			font-size: 60px;
			line-height: 1;
		}

		@media screen and (max-height: 550px) {
			font-size: 30px;
			line-height: 36px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 30px;
			line-height: 36px;
		}
		
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			left: 0;
			background-color: $brand-primary;
			display: block;
			transform: translate3d(-101%, 0, 0);
			transition: transform .2s ease-in-out;

			@include media-breakpoint-up(xl) {
				top: calc(50% - 20px);
				height: 30px;
			}

			@include media-breakpoint-down(lg) {
				height: 15px;
				top: calc(50% - 12px);
			}

			@media screen and (max-height: 800px) {
				height: 15px;
				top: calc(50% - 12px);
			}

			@media screen and (max-height: 550px) {
				height: 5px;
				top: calc(50% - 4px);
			}

			@include media-breakpoint-down(sm) {
				height: 5px;
				top: calc(50% - 4px);
			}
		}

		&:hover {
			color: $brand-primary;
			transition: color .2s ease-in-out;

			&::before {
				transform: translate3d(0, 0, 0);
  			transition: transform .2s ease-in-out;
  		}
		}
		
		&.is-active {
			color: $brand-primary;
			transition: color .2s ease-in-out;

			&::before {
				transform: translate3d(0, 0, 0);
				transition: transform .2s ease-in-out;
			}
		}
	}
}

.bottom-link-block {
	display: flex;
	position: fixed;
	bottom: 32px;
	z-index: $zindex-navbar;

	@include media-breakpoint-up(lg) {
		left: 25%;
	}

	@include media-breakpoint-down(md) {
		left: 30px;
	}

	@media screen and (max-height: 750px) {
		bottom: 22px;
	}

	a {
		display: block;
		margin-right: 30px;
		font-size: $font-size-base;

		&:last-child {
			margin-right: 0;
		}
	}
}