.decimal-zero-list {
	counter-reset: number;
	list-style-type: none;
	padding: 0;

	li[data-model] {
		transition: opacity linear .1s;
		opacity: 0.2;

		&.active {
			opacity: 1;
		}
	}

	@include media-breakpoint-up(lg) {

		li {
			margin-bottom: 93px;
		}

		.btn-group {
			padding-top: 38px;
		}
	}

	@include media-breakpoint-down(md) {

		li {
			margin-bottom: 77px;
		}

		.btn-group {
			padding-top: 23px;
		}
	}
}