$bg-light-gray: #fafafa;
$bg-gray: #f0f0f0;
$bg-highlight: #fff;
$bg-selected: #e8f3fa;
$border-color: #e0e0e0;
$border-color-highlight: #72c0f1;
$text-dark: #1f1f1f;
$text-gray: #7f7f7f;
$text-white: #fff;
$time-cell-height: 50px;

.erc {
  font-family: $font-family-base;

  &.is-modal {
    -webkit-overflow-scrolling: touch;
    background-color: rgba($text-dark, .5);
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: hidden;
    visibility: hidden;
    z-index: 10000;
    clip: rect(1px, 1px, 1px, 1px);

    .erc-container {
      max-width: 1120px;
    }

    &.is-touch {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    &.not-touch {
      overflow-y: auto;
    }
  }

  &.is-shown {
    clip: inherit;
    visibility: visible;
  }

  &.is-loading {
    .erc-main::after {
      display: block;
    }

    .erc-header-preloader {
      visibility: visible;
    }
  }

  &.not-touch {
    .erc-schedule-time {
      &.is-free:hover {
        background-color: $bg-highlight;
        border-color: $border-color-highlight;
      }
    }

    .erc-event-actions {
      button:hover {
        circle {
          fill: #D01C45;
        }

        polygon {
          fill: #FFFFFF;
        }
      }
    }

    .erc-modal-button {
      transition: border-color 0.2s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out;
      will-change: border-color, color, background-color;

      &:hover {
        &.is-secondary {
          border-color: $text-gray;
          color: $text-gray;
        }

        &.is-primary {
          background-color: #c31b41;
          border-color: #c31b41;
        }
      }
    }
  }
}

// --- Container

.erc-container {
  background-color: #fff;
}

// --- Header

.erc-header {
  color: $text-dark;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.erc-header-actions {
  align-items: center;
  display: flex;
  flex: 0 0 auto;

  &:first-child {
    padding-right: 8px;
    justify-content: flex-end;
    order: 2;
  }

  &:last-child {
    padding-left: 8px;
    justify-content: flex-start;
    order: 3;
  }
}

.erc-header-btn {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;

  svg {
    width: 28px;
    height: 28px;
  }
}

.erc-header-btn + .erc-header-btn {
  margin-left: 6px;
}

.erc-header-info {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  padding-left: 28px;
  padding-right: 28px;
  order: 1;
  justify-content: center;
  margin-bottom: 24px;
}

.erc-header-date {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  //min-width: 132px;

  &:before {
    color: $text-gray;
    content: attr(data-label);
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.erc-header-divider {
  flex-shrink: 1;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
}


// --- Main

.erc-main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  &::after {
    background-color: rgba(255, 255, 255, 0.8);
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.is-collapsed {
    overflow: hidden;
  }
}

// --- Prealoader

.erc-header-preloader {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;

  svg {
    width: 42px;
    height: 42px;
  }

  path {
    fill: $text-dark;
  }

  &.is-visible {
    display: flex;
  }
}

// --- Footer

.erc-footer {
  background-color: $bg-light-gray;
  border-bottom: 1px solid $border-color;
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;

  &.is-hidden {
    display: none;
  }

  a {
    display: inline-block;
    font-size: 12px;
    line-height: 32px;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;

    &,
    &:hover,
    &:active,
    &:visited {
      color: $text-gray;
      text-decoration: none;
    }

    &:after {
      background-color: $text-gray;
      content: '';
      display: block;
      height: 1px;
      position: relative;
      top: -5px;
    }
  }
}

// --- Weekdays

.erc-weekdays {
  display: flex;
  box-shadow: 0 1px 2px rgba($text-dark, 0.1);
  border-bottom: 1px solid $border-color;
  flex: 1 1 100%;
  position: relative;
  z-index: 10;
}

.erc-weekday {
  background-color: #fff;
  border-top: 1px solid $border-color;
  color: $text-gray;
  height: 49px;
  line-height: 50px;
  flex: 0 0 calc(100% / 4);
  font-family: $font-family-brand-mono;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;
  position: relative;

  & + .erc-weekday {
    border-left: 1px solid $border-color;
  }
}


// --- Timeline

.erc-timeline {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(100% / 4);
}

.erc-timeline-time {
  background-color: $bg-light-gray;
  border-bottom: 1px solid $border-color;
  color: $text-gray;
  pointer-events: none;
}

// --- Schedule

.erc-schedule {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
}

.erc-schedule-time {
  background-color: $bg-light-gray;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent $border-color $border-color;
  width: calc(100% / 3);
  position: relative;
  -webkit-user-callout: none;
  -webkit-user-select: none;

  &.is-focused {
    background-color: $bg-highlight;
    border-color: $border-color-highlight;
  }

  &.is-head,
  &.is-tail {
    &::after {
      content: attr(data-time);
    }
  }

  &.is-highlighted {
    background-color: $bg-highlight;
    cursor: pointer;

    &.is-head {
      border-top-color: $border-color-highlight;
    }

    &.is-tail {
      border-bottom-color: $border-color-highlight;
    }
  }

  &.is-selected {
    background: $bg-selected;
  }

  &.is-highlighted,
  &.is-selected {
    &.is-booked {
      background-color: #ff4e4e;
      color: #a32e2e;
    }
  }

  &.is-disabled {
    background-color: $bg-gray;
    cursor: not-allowed;
  }

  &.is-booked {
    &.is-highlighted::after {
      content: 'Booked';
    }
  }
}

// ---

.erc-timeline-time,
.erc-schedule-time  {
  height: $time-cell-height;
  font-family: $font-family-brand-mono;
  font-size: 12px;
  line-height: $time-cell-height;
  text-align: center;
  user-select: none;
}

// --- Events

.erc-events {
  &:not(:empty) {
    border-bottom: 1px solid $border-color;
    padding: 30px;
  }
}

.erc-event {
  background-color: $text-dark;
  color: $text-white;
  display: flex;
  padding-left: 20px;
  padding-right: 14px;
  height: 50px;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
}

.erc-event-title {
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.erc-event-actions {
  flex-shrink: 1;
  padding-left: 20px;

  button {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

// --- Modal

.erc-modal-footer {
  display: flex;
  padding: 30px;
  align-items: center;
  flex-direction: column;
}

.erc-modal-legend {
  color: $text-gray;
  font-size: 11px;
  text-align: center;
  order: 2;
  line-height: 1;

  &::before {
    content: '* ';
    color: #D01C45;
  }
}

.erc-modal-actions {
  display: flex;
  margin-bottom: 30px;
  order: 1;
}

.erc-modal-button {
  background-color: #fff;
  border: 2px solid $text-dark;
  color: $text-dark;
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  height: 46px;
  width: 120px;
  text-align: center;

  & + & {
    margin-left: 20px;
  }

  &.is-primary {
    background-color: #D01C45;
    border-color: #D01C45;
    color: #fff;
  }
}

// -- Utils

.erc-is-locked {
  overflow: hidden !important;
}


// --- Media

@media (min-width: 768px) {
  .erc {
    &.is-modal {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .erc-container {
    margin-left: auto;
    margin-right: auto;
  }

  .erc-header {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: nowrap;
  }

  .erc-header-info {
    flex: 0 0 auto;
    order: 2;
    margin-bottom: 0;
  }

  .erc-header-date {
    font-size: 14px;
  }

  .erc-header-actions {
    flex: 1;

    &:first-child {
      padding-right: 0;
      order: 1;
    }

    &:last-child {
      padding-left: 0;
      order: 3;
    }
  }

  .erc-header-btn {
    svg {
      width: 34px;
      height: 34px;
    }
  }

  .erc-weekday,
  .erc-timeline {
    flex: 0 0 calc(100% / 8);
  }

  .erc-weekday {
    height: 69px;
    line-height: 70px;
  }

  .erc-schedule-time {
    width: calc(100% / 7);
  }

  .erc-events {
    &:not(:empty) {
      padding: 50px;
    }
  }

  .erc-event {
    padding-left: 30px;
    padding-right: 25px;
    height: 70px;
  }

  .erc-event-title {
    font-size: 14px;

    span {
      margin-left: 17px;
      margin-right: 17px;
    }
  }

  .erc-event-actions {
    button {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  .erc-modal-footer {
    padding: 50px;
    flex-direction: row;
  }

  .erc-modal-legend {
    flex: 1 1 auto;
    order: 1;
    text-align: left;
    font-size: 12px;
  }

  .erc-modal-actions {
    margin-bottom: 0;
    flex: 0 0 auto;
    order: 2;
  }

  .erc-modal-button {
    font-size: 14px;
    height: 66px;
    width: 140px;

    &.is-primary {
      width: 180px;
    }

    & + & {
      margin-left: 50px;
    }
  }
}