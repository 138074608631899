.welcome {
	position: relative;
	max-width: 100%;
	height: 100vh;
	
  @include media-breakpoint-up(lg) {
		min-height: 800px;
		overflow: hidden;
	}

	@include media-breakpoint-down(md) {
		min-height: 568px;
		background-image: url(/assets/img/site/ahoyberlin.jpg);
		background-position: center;
	  background-size: cover;
	  background-color: #b0d2e6;
	  background-blend-mode: overlay;
	}

  &::before {

  	@include media-breakpoint-up(lg) {
  		content: "";
			position: absolute;
	  	top: 0;
	  	left: 50%;
	  	width: 37px;
	  	height: 37px;
	  	background-color: $gray-dark;
	  	z-index: 2;
		}
  }
}