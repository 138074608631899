.btn {

	@include media-breakpoint-up(lg) {
		@include button-size($btn-padding-y, $btn-padding-x, $font-size-lg, $btn-border-radius);
	}

	@include media-breakpoint-down(md) {
		@include button-size($btn-mobile-padding-y, $btn-mobile-padding-x, $font-size-sm, $btn-border-radius);
	}
}

.btn-lg {

  @include media-breakpoint-up(lg) {
		@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
	}

	@include media-breakpoint-down(md) {
		@include button-size($btn-mobile-padding-y-lg, $btn-mobile-padding-x-lg, $font-size-sm, $btn-border-radius-lg);
	}
}

.btn-primary {

	&:hover {
		background-color: $btn-primary-bg-hover;
		border-color: $btn-primary-bg-hover;
	}
}