.space-info {
	position: relative;
	z-index: 2;

	@include media-breakpoint-up(xl) {
		align-items: flex-start;
	}

	@include media-breakpoint-down(lg) {
		align-items: center;
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: flex-end;
		padding: 0 0 140px;
	}
	
	@include media-breakpoint-down(md) {
		max-width: 480px;
		margin: -25px auto 0;
		padding: 0px $container-padding;
	}
}