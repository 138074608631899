.with-separator {
	position: relative;
	font-weight: $font-weight-bold;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
		line-height: 30px;
		margin-right: 80px;
		flex: 0 1 430px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 26px;
		flex: 0 1 420px;
	}

	@include media-breakpoint-up(lg) {
		padding-left: 30px;
	}

	@include media-breakpoint-down(md) {
		width: auto;
		flex: 0 1 420px;
	}

	&.with-separator-primary {

		&::after {
			background-color: $primary-color-with-transparent;
		}
	}

	&.with-separator-blue {

		&::after {
			background-color: $blue-color-with-transparent;
		}
	}

	&::after {
		content: "";
		display: block;
		position: absolute;

		@include media-breakpoint-up(xl) {
			width: 80px;
			height: 10px;
			bottom: -102px;
		}

		@include media-breakpoint-down(lg) {
			width: 50px;
			height: 5px;
			left: 30px;
			bottom: -52px;
		}

		@include media-breakpoint-down(md) {
			left: auto;
			right: -30px;
		}
	}
}