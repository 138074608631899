.scroll-row {
	display: flex;
	position: absolute;
	bottom: 0;
	z-index: 2;

	@include media-breakpoint-up(lg) {
		padding: 30px 0 70px;
		left: calc(25% - 10px);
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
		padding: 0 0 15px;
		width: 100%;
	}

	.scroll-info-text {
		display: flex;
		align-items: center;
		margin-left: 30px;
	}
}

.scroll-icon-block {
	position: relative;

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 10px;
		width: 1px;
		background-color: $gray-dark;
	}

	&::before {
		
		@include media-breakpoint-up(lg) {
			height: 30px;
			top: -30px;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&::after {

		@include media-breakpoint-up(lg) {
			bottom: -70px;
			height: 70px;
		}

		@include media-breakpoint-down(md) {
			bottom: -30px;
			height: 30px;
		}
	}
}

.scroll-container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	z-index: 2;

	@include media-breakpoint-up(lg) {
		bottom: -40px;
	}

	@include media-breakpoint-down(md) {
		bottom: -30px;
	}
}

.scroll-column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;

	&:after {
		content: "";
		display: block;
		width: 1px;
		background-color: $gray-dark;

		@include media-breakpoint-up(lg) {
			height: 80px;
		}

		@media (max-height: 500px) {
			height: 60px;
		}

		@include media-breakpoint-down(md) {
			height: 60px;
		}
	}

	.scroll-info-text {
		padding: 25px 0 32px;

		@media (max-height: 500px) {
			display: none;
		}
	}
}

.scroll-mouse-icon {
	position: relative;
	width: 21px;
	height: 31px;
	border: 2px solid $gray-dark;
	border-radius: 10px;
	display: block;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		top: 6px;
		left: 7px;
		display: block;
		border-radius: 1px;
		width: 3px;
		height: 7px;
		background-color: $gray-dark;
	}
}

.scroll-info-text {
	display: block;
	font-weight: $font-weight-bold;
}