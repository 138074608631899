.share-event {

	@include media-breakpoint-down(md) {
		background-color: $blue-lightest-color-with-transparent;
		margin-right: $negative-container-margin;
		margin-left: $negative-container-margin;
		padding: 50px $container-padding 40px;
	}
}

.share-nav {

	@include media-breakpoint-up(lg) {
		padding-top: 45px;
		float: left;

		.share,
		a {
			float: left;
			margin-right: 27px;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;

		a {
			display: inline-block;
			margin-bottom: 3px;

			&:first-of-type {
				padding-top: 15px;
			}
		}

		.share {
			border-bottom: 2px solid $gray-dark;
			padding-bottom: 8px;
			font-weight: $font-weight-bold;
		}
	}
}