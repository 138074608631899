.progress-custom {
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		height: 5px;
	}

	@include media-breakpoint-down(md) {
		height: 2px;
	}
}


@keyframes progressBarStripes {
  
  from {
  	background-position: $spacer-y 0; 
  }

  to {
  	background-position: 0 0; 
  }
}

.progress-animated[value]::-webkit-progress-value {
  animation: progressBarStripes 2s linear infinite;
}

.progress-animated[value]::-moz-progress-bar {
  animation: progressBarStripes 2s linear infinite;
}

// IE9
@media screen and (min-width: 0\0) {

  .progress-animated .progress-bar-striped {
    animation: progressBarStripes 2s linear infinite;
  }
}