.fixed-questions {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $white;
	background-image: url(/assets/img/site/patern.png);
	background-repeat: repeat;
	border-top: 1px solid $blue-color-with-transparent;
	z-index: $zindex-navbar;

	@include media-breakpoint-up(lg) {
		padding: 37px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding;
	}
}

.questions {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include media-breakpoint-down(md) {
		min-height: 91px;
	}
}