.post-event-survey-v2 {
	background-color: $blue-lightest-color-with-transparent;

	form {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 0 0 287px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding 172px;
	}

	.btn-group {

		.btn{
			padding:0;
		}
		
		input[type="checkbox"], 
		input[type="radio"] {
			clip: rect(0px, 0px, 0px, 0px);
    	pointer-events: none;
    	position: absolute;
    	padding: 0;

    	+ span {
    		display: inline-block;

    		@include media-breakpoint-up(lg) {
					min-width: 50px;
	    		padding: 16px 0;
				}

	    	@include media-breakpoint-down(md) {
					min-width: 40px;
	    		padding: 14px 0;
	    		line-height:14px;
				}
    	}

    	&:checked {

    		+ span {
    			background-color: $brand-primary;
    		}
    	}
		}
	}
}

.newer-submit {
	display: block;
	padding-top: 25px;
}

[data-model-container] {
	position: relative;

	&::after {
		display: block;
		content:"";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 173px;
		background-color: $blue-lightest-color-with-transparent;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 173px;
	}

	@include media-breakpoint-down(md) {
		padding-top:  77px;

		&::after {
			height: 77px;
		}
	}
}
