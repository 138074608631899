[data-animation] {
  @for $i from 1 through 200 {
    body[data-animation-duration='#{$i * 100}'] &,
    &[data-animation][data-animation-duration='#{$i * 100}'] {
      transition-duration: #{$i * 100}ms;
    }

    body[data-animation-delay='#{$i * 100}'] &,
    &[data-animation][data-animation-delay='#{$i * 100}'] {
      transition-delay: 0;

      &.animate {
        transition-delay: #{$i * 100}ms;
      }
    }
  }
}

// Easings implementations
// Default timing function: 'ease'

[data-animation] {
  @each $key, $val in $animation-easing {
    body[data-animation-easing="#{$key}"] &,
    &[data-animation][data-animation-easing="#{$key}"] {
      transition-timing-function: $val;
    }
  }
}

[data-animation^='fade'][data-animation^='fade'] {
  @include media-breakpoint-up(md) {
    opacity: 0;
    transition-property: opacity, transform;
  }

  &.animate {
    @include media-breakpoint-up(md) {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

[data-animation='fade-up'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, $animation-distance, 0);
  }
}

[data-animation='fade-up-community-img'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, $animation-distance-community-left-img, 0);
  }
}

[data-animation='fade-down'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, -$animation-distance, 0);
  }
}

[data-animation='fade-right'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(-$animation-distance, 0, 0);
  }
}

[data-animation='fade-left'] {
  @include media-breakpoint-up(md) {
    transform: translate3d($animation-distance, 0, 0);
  }
}

[data-animation='fade-up-right'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(-$animation-distance, $animation-distance, 0);
  }
}

[data-animation='fade-up-left'] {
  @include media-breakpoint-up(md) {
    transform: translate3d($animation-distance, $animation-distance, 0);
  }
}

[data-animation='fade-down-right'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(-$animation-distance, -$animation-distance, 0);
  }
}

[data-animation='fade-down-left'] {
  @include media-breakpoint-up(md) {
    transform: translate3d($animation-distance, -$animation-distance, 0);
  }
}

[data-animation^='slide'][data-animation^='slide'] {
  @include media-breakpoint-up(md) {
    transition-property: transform;
  }

  &.animate {
    @include media-breakpoint-up(md) {
      transform: translate3d(0, 0, 0);
    }
  }
}

[data-animation='slide-up'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, 100%, 0);
  }
}

[data-animation='slide-up-community-img'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, $animation-distance-community-left-img, 0);
  }

  &.animate {
    @include media-breakpoint-up(md) {
      transform: translate3d(0, 0, 0);
    }
  }
}

[data-animation='slide-down'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, -100%, 0);
  }
}

[data-animation='slide-right'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(-100%, 0, 0);
  }
}

[data-animation='slide-left'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(100%, 0, 0);
  }
}

.custom-animation {
	position: relative;
	overflow: hidden;
	display: block;

  picture {

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
}

.white-bg {

  @include media-breakpoint-up(md) {
  	position: absolute;
  	top: 0;
  	bottom: 0;
  	right: 0;
  	left: 0;
  	display: block;
  	background-color: $white;
  	z-index: 1;
  }
}

[data-animation='animate-bg'] {
  @include media-breakpoint-up(md) {
	 transform: translate3d(0, 0, 0);
  }

  &.animate {
  	@include media-breakpoint-up(md) {
      transform: translate3d(-100%, 0, 0);
  	  transition-property: transform;
    }
  }
}

[data-animation="video-button"] {
  @include media-breakpoint-up(md) {
    opacity: 0;
  }

  &.animate {
    @include media-breakpoint-up(md) {
      opacity: 1;
      transition: opacity .5s cubic-bezier(.455, .03, .515, .955) 6.65s;
    }
  }
}

[data-animation='text'] {
  @include media-breakpoint-up(md) {
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d($animation-distance, 0, 0);
  }

  &.animate {
    @include media-breakpoint-up(md) {
      opacity: 0.4;
      transform: translate3d(0, 0, 0);
    }
  }
}

[data-animation="link-border-animation"] {
  @include media-breakpoint-up(md) {
	 overflow: hidden;
  }

	&::before {
    @include media-breakpoint-up(md) {
		  transform: translate3d(-100%, 0, 0);
    }
	}

	&.animate {
		
		&::before { 
			@include media-breakpoint-up(md) {
        transform: translate3d(0, 0, 0);
			  transition-duration: .5s;
      }
		}
	}
}

[data-animation='fade-up-get-in-touch'] {
  @include media-breakpoint-up(md) {
    transform: translate3d(0, $animation-distance-get-in-touch, 0);
  }
}

.animated-letter,
.animated-image-left img,
.animated-image-right img,
.animated-image-hidden img,
.animated-block1,
.animated-block2,
.animated-block3,
.animated-block4,
.animated-block5{
  @include media-breakpoint-up(md) {
    backface-visibility: hidden;
    transition: all 1s cubic-bezier(0.26, 1.48, 0.47, 0.96) 0s;
  }
}

.animated-block1,
.animated-block2{
  backface-visibility: hidden;
  transition: transform 0.3s cubic-bezier(0.26, 1.48, 0.47, 0.96) 0s;
}

.animated-block3,
.animated-block4,
.animated-block5,
.animated-block6{
  backface-visibility: hidden;
  transition: transform 0.3s cubic-bezier(0.26, 1.48, 0.47, 0.96) 0s;
}

html {
  
  &:not(.is-mobile) {
    width: 100%;
    height: 100%;  

    body {
      width: 100%;
      height: 100%;
    }

    #scrollbar {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}