.home-about-us {
	max-width: 480px;
	text-align: center;
	font-weight: $font-weight-bold;

	@include media-breakpoint-up(xl) {
		font-size: 30px;
		line-height: 36px;
		margin: 0 auto 138px;
	}

	@include media-breakpoint-down(lg) {
		padding: 0 $container-padding;
		margin: 0 auto 45px;
		font-size: 20px;
		line-height: 24px;
	}

	.display-2 {
		display: block;

		@include media-breakpoint-up(xl) {
			padding: 100px 0 65px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 46px;
			padding: 0 0 43px;
		}

		img {
			display: inline-block;

			@include media-breakpoint-up(xl) {
				width: 34px;
				height: 26px;
			}

			@include media-breakpoint-down(lg) {
				width: 18px;
				height: 14px;
			}
		}		
	}
}