.community-left-desc {
	position: relative;
	font-weight: $font-weight-bold;

	@include media-breakpoint-up(xl) {
		font-size: 30px;
		line-height: 36px;
		margin: 0 80px 45px 0;
		flex: 0 1 400px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 20px;
		line-height: 24px;
		padding: 0 $container-padding $container-padding;
		flex: 0 1 480px;
	}
}