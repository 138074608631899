/* 
	* Table of Contents
  
	* Colors
	* Options
	* Spacing
	* Body
	* Links
	* Grid breakpoints
	* Grid containers
	* Grid columns
	* Fonts
	* Components 
*/

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

/* Color */
$gray-dark:                 #1f1f1f;
$blue:                      #b0d2e6;
$white: 										#fff;
$black:                     #000;
$transparent: 							transparent;
$big-letter-color:          #ff003a;

$red_e7:                    #e78da2;

$gray_7f:                   #7f7f7f;
$gray_d2:                   #d2d2d2;
$gray_e0:                   #e0e0e0;
$gray_e6:                   #e6e6e6;

$brand-primary:             #d01c45;
$brand-inverse:             $gray-dark;

/* Color with transparent */
$primary-color-with-transparent:                   rgba(208, 28, 69, .03);
$primary-lightest-color-with-transparent:          rgba(208, 28, 69, .03);

$blue-color-with-transparent:                      rgba(28, 142, 208, .03);
$blue-lightest-color-with-transparent:             rgba(28, 142, 208, .03);

/* Options */
$enable-flex: true;
$enable-transitions: true;
$enable-hover-media-query: true;

/* Spacing */
$border-width: 0;

/* Body */
$body-bg:    $white;
$body-color: $gray-dark;

/* Links */
$link-color:            $gray-dark;
$link-decoration:       none;
$link-hover-color:      $gray-dark;
$link-hover-decoration: none;

/* Grid breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");

/* Grid containers */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");

$container-padding:  1.875rem;
$negative-container-margin: -1.875rem;

/* Grid columns */
$grid-columns:               8;
$grid-gutter-width-base:     0px;

/* Fonts */
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-base: MaisonNeue, $font-family-sans-serif;
$font-family-brand-mono: MaisonMono, $font-family-sans-serif;

$font-size-base:        1rem;
$font-size-base-mobile: .875rem;

$font-size-lg:          1.25rem;
$font-size-sm:          .875rem;

$font-size-h2: 2.5rem;
$font-size-h3: 1.87rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$font-size-big-letter:      55rem;
$font-size-mobile-big-letter: 18.75rem;

$font-size-big-text:        18.75rem;
$font-size-mobile-big-text: 6.25rem;

$mobile-font-size-h2: 1.25rem;
$mobile-line-height-h2: 1.2;

$display2-size: 5.75rem;
$display2-weight: 700;

$headings-font-weight: 700;
$font-weight-bold: 700;
$font-weight-regular: 400;

/* 
  * Components
  
  * Define common padding and border radius sizes and more.
  * Buttons
  * Z-index master list
  * Navs
  * Progress bars
*/

/* Define common padding and border radius sizes and more. */
$border-radius:    0;
$border-radius-lg: 0;
$border-radius-sm: 0;

/* Buttons */
$btn-padding-x:        1rem;
$btn-padding-y:        2.4375rem;
$btn-mobile-padding-x: 1rem;
$btn-mobile-padding-y: 1.4375rem;

$btn-padding-x-lg:               1rem;
$btn-padding-y-lg:               4.844rem;
$btn-mobile-padding-x-lg:        1rem;
$btn-mobile-padding-y-lg:        1.657rem;

$btn-font-weight: $font-weight-bold;

$btn-primary-color:    $white;
$btn-primary-bg:       $brand-primary;
$btn-primary-border:   $btn-primary-bg;

$btn-secondary-color:  $white;
$btn-secondary-bg:     $gray-dark;
$btn-secondary-border: $gray-dark;

$btn-primary-bg-hover: #c31b41;


$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

/* Z-index master list */
$zindex-close: 1;
$zindex-navbar:1000;
$zindex-modal: 1050;
$z-index-negative: -1px;

/* Navs */
$nav-item-margin:               auto;
$nav-item-inline-spacer:        auto;
$nav-link-padding:              auto;
$nav-link-hover-bg:             $transparent;
$nav-disabled-link-color:       $transparent;
$nav-disabled-link-hover-color: $transparent;
$nav-disabled-link-hover-bg:    $transparent;

$nav-tabs-border-color:                       $transparent;
$nav-tabs-border-width:                       $border-width;
$nav-tabs-border-radius:                      $border-radius;
$nav-tabs-link-hover-border-color:            $transparent;
$nav-tabs-active-link-hover-color:            $gray-dark;
$nav-tabs-active-link-hover-bg:               $transparent;
$nav-tabs-active-link-hover-border-color:     $transparent;
$nav-tabs-justified-link-border-color:        $transparent;
$nav-tabs-justified-active-link-border-color: $transparent;

/* Progress bars */
$progress-bg:                 rgba(208, 28, 69, .2);
$progress-bar-color:          $brand-primary;

/* Animation */
$animation-distance: 15px;
$animation-distance-community-left-img: 100px;
$animation-distance-get-in-touch: 300px;


$animation-easing: (
  linear: cubic-bezier(.250, .250, .750, .750),

  ease: cubic-bezier(.250, .100, .250, 1),
  ease-in: cubic-bezier(.420, 0, 1, 1),
  ease-out: cubic-bezier(.000, 0, .580, 1),
  ease-in-out: cubic-bezier(.420, 0, .580, 1),

  ease-in-back: cubic-bezier(.6, -.28, .735, .045),
  ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
  ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55),

  ease-in-sine: cubic-bezier(.47, 0, .745, .715),
  ease-out-sine: cubic-bezier(.39, .575, .565, 1),
  ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),

  ease-in-quad: cubic-bezier(.55, .085, .68, .53),
  ease-out-quad: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),

  ease-in-cubic: cubic-bezier(.55, .085, .68, .53),
  ease-out-cubic: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-cubic: cubic-bezier(.455, .03, .515, .955),

  ease-in-quart: cubic-bezier(.55, .085, .68, .53),
  ease-out-quart: cubic-bezier(.25, .46, .45, .94),
  ease-in-out-quart: cubic-bezier(.455, .03, .515, .955)
);

/* slider */
$slick-arrow-color: $gray-dark;
$slick-dot-color: $gray-dark;
$slick-opacity-not-active: 0.35;