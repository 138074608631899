.border-rose-virtual-offices {

	@include media-breakpoint-up(lg) {
		padding-bottom: 180px;
	}

	&::after {
		content: "";
		position: absolute;

		@include media-breakpoint-up(lg) {
			border: 30px solid rgba(208, 28, 69, .05);
			top: 29.63%;
			right: 33.33%;
			height: 70.5%;
			width: 33.33%;
		}
		
		@include media-breakpoint-down(md) {
			border-top: 30px solid rgba(208, 28, 69, .05);
			border-bottom: 30px solid rgba(208, 28, 69, .05);
			border-right: 30px solid rgba(208, 28, 69, .05);
			left: 0;
			top: 0;
			height: 100%;
			width: calc(50% - 30px);
		}
	}
}