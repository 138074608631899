.partners-section {
	position: relative;
	width: 100%;
	display: flex;
	align-items: flex-start;

	@include media-breakpoint-up(xl) {
		padding-top: 180px;
		margin-bottom: 180px;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 261px;
		margin-bottom: 110px;
	}
	
	.rotated-page-name {

		@include media-breakpoint-down(lg) {
			top: 90px;
		}
	}
}