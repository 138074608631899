.event-where {

	@include media-breakpoint-up(lg) {
		margin-bottom: 46px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 41px;
	}

	strong {
		display: block;
		line-height: 1.7;

		@include media-breakpoint-up(lg) {
			padding-top: 25px;
		}

		@include media-breakpoint-down(md) {
			padding-top: 16px;
		}
	}
}

.event-contact-info {

	@include media-breakpoint-down(md) {
		margin-bottom: 40px;
	}

	.info-link {
		display: block;
		margin-bottom: 3px;

		@include media-breakpoint-up(lg) {
			padding-top: 26px;
		}

		@include media-breakpoint-down(md) {
			padding-top: 16px;
		}
	}
}