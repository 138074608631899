.our-family{
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-bottom: 94px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 176px;
	}
	
	.title-h3 {
		line-height: 1.2;
		text-align: center;
		position: relative;

		&:before {

			@include media-breakpoint-down(md) {
				content: "";
				display: block;
				position: absolute;
				top: -126px;
				left: 50%;
				transform: translate3d(-50%, 0, 0);
				width: 1px;
				height: 50px;
				background-color: $gray-dark;
			}
		}

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}

		@include media-breakpoint-up(lg) {  
			padding-top: 172px;
			padding-bottom: 160px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 44px;	
		}
	}

	.item-left,
	.item-right {

		@include media-breakpoint-up(lg) {
			margin-bottom: 80px;
		}
	}

	.item-left {

		.family-img {
			justify-content: flex-end;
			
			@include media-breakpoint-up(lg) { 
				text-align: right;
			}
		}

		.family-content {

			@include media-breakpoint-up(lg) { 
				padding-left: $container-padding;
			}
		}
	}

	.item-right {

		.family-img {

			@include media-breakpoint-down(lg) {
				justify-content: center;
			}
			
			@include media-breakpoint-up(lg) {
				order: 1;
				justify-content: flex-start;
			}
		}
	}

	.family-img {
		align-items: center;
		justify-content: flex-end;
		display: flex;
		
		div {
			background-color: $blue-lightest-color-with-transparent;
			align-items: center;
			justify-content: center;
			display: flex;
			padding: 10px;

			@include media-breakpoint-up(lg) { 
				min-width: 480px;
				min-height: 320px;
			}

			@include media-breakpoint-down(md) {
				width: 100%;
				min-height: 220px;
			}
		}

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom: 47px;
		}

		.img-fluid {

			@include media-breakpoint-up(lg) {
				max-width: 220px;
			}

			@include media-breakpoint-down(md) {
				max-width: 180px;
			}
		}
	}

	.family-info {
		display: flex;
		align-items: center;
		
		@include media-breakpoint-down(md) {
			max-width: 480px;
			margin: 0 auto 42px;
			padding: 0px $container-padding;
		}

		@include media-breakpoint-up(xl) { 
			justify-content: flex-end;
		}
	}

	.family-content {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(xl) { 
			width: 591px;
		}
	}

	.family-name {
		font-family: $font-family-brand-mono;
		font-weight: $font-weight-bold;
		display: block;
		line-height: 1.2;

		@include media-breakpoint-up(xl) { 
			font-size: 30px;
			margin-bottom: 32px;
		}

		@include media-breakpoint-down(xl) { 
			font-size: 20px;
		}
	}

	.family-desc {
		position: relative;
		align-self: flex-end;

		@include media-breakpoint-up (xl) { 
			margin-right: 90px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 390px;
		}
		
		@include media-breakpoint-down(lg) {
			padding-top: 10px;
		}
		
		p {

			@include media-breakpoint-up(xl) {  
				font-size: 20px; 
				line-height: 30px;
				margin-bottom: 34px;
			}

			@include media-breakpoint-down(xl) { 
				font-size: 16px;
				line-height: 1.625;
				margin-bottom: 14px;
			}
		}

		a {
			text-decoration: none;
			font-weight: $font-weight-bold;
		}
	}
}