.big-text {
	display: inline-block;
	position: absolute;
	font-weight: $font-weight-bold;
	font-family: $font-family-brand-mono;
	line-height: 1;
	z-index: 0;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		font-size: $font-size-big-text;
	}

	@include media-breakpoint-down(md) {
		font-size: $font-size-mobile-big-text;
	}
}

.big-text-in-space-info-left,
.big-text-in-space-info-right {
	z-index: -2;

	@include media-breakpoint-up(xl) {
		top: 180px;
	}

	@include media-breakpoint-down(lg) {
		font-size: $font-size-mobile-big-text;
		top: 90px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.big-text-in-space-info-left {
	left: 0;
}

.big-text-in-space-info-right {
	right: 0;
}

.big-text-primary {
	color: $primary-color-with-transparent;
}

.big-text-blue {
	color: $blue-color-with-transparent;
}