.space-content {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	
	@include media-breakpoint-up(xl) { 
		width: 593px;
		padding-top: 85px;
		justify-content: center;
	}
}

.space-content-left {

	@include media-breakpoint-down(lg) {
		justify-content: flex-start;
	}
	
	@include media-breakpoint-up(lg) {
		padding-left: 30px;
	}
}

.space-content-right {
	
	@include media-breakpoint-up(lg) {
		padding-right: 30px;
	}
}