.welcome-desc {
	z-index: 4;

	@include media-breakpoint-up(lg) {
		font-size: 20px;
		line-height: 30px;
		padding-bottom: 0px;
	}

	@include media-breakpoint-down(md) {
		line-height: 20px;
	}

	.display-2 {

		@media screen and (min-width: 1650) {
			font-size: inherit;
		}

		@media screen and (max-width: 1649px) and (min-width: 1430px) {
			font-size: 82px;
		}

		@media screen and (max-width: 1429px) and (min-width: 1201px) {
			font-size: 68px;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 7px;
		}
	}
}