.our-spaces {
	text-align: center;

	@include media-breakpoint-up(lg) {
		padding-top: 172px;
		padding-bottom: 172px;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 76px;
		padding-bottom: 36px; 
	}
	
	@include media-breakpoint-down(md) {
		max-width: 480px;
		margin: 0 auto;
		padding-left: $container-padding;
		padding-right: $container-padding;
	}

	.title-h3 {
		line-height: 1.2;

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}

		@include media-breakpoint-up(md) {
			max-width: 840px;
			margin: 0 auto;
		}
	}
}