.link-with-border-red {
	position: relative;
	font-weight: $font-weight-bold;
	padding-top: 19px;
	display: inline-block;
	cursor: pointer;
	padding-bottom: 17px;

	@include media-breakpoint-down(md) {
		font-size: 16px;	
	}

	&:hover {

		&::before {

			@include media-breakpoint-up(lg) {
				width: 80%;
			}
		}
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: $brand-primary;

		@include media-breakpoint-up(lg) {
			height: 5px;
		}

		@include media-breakpoint-down(md) {
			height: 3px;
		}
	}
}