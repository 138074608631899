.has-float-label {
  display: block;
  position: relative;
	
	label {
		position: absolute;
		left: 0;
		top: 0;
		cursor: text;
		font-size: 12px;
		opacity: 1;
		color: rgba(31, 31, 31, .5);
		transition: all .2s;
		font-weight: $font-weight-bold;
	}

	input[type="text"],
	input[type="email"],
	textarea{
	  border-style: none none solid none;
	  border-radius: 0;
	  border-color: $gray-dark;
	  background-color: $transparent;
	  box-shadow: none;
	  width: 100%;
	  padding-right: 40px;
	  font-weight: $font-weight-bold;

	  @include media-breakpoint-up(xl) { 
			font-size: 20px;
		}

	  @include media-breakpoint-up(lg) { 
			border-width: 0px 0px 5px 0px;
			padding-top: 32px;
	  	padding-bottom: 22px;
	  	margin-bottom: 2px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 16px;
		} 

	  @include media-breakpoint-down(md) { 
			border-width: 0px 0px 3px 0px;
			padding-top: 32px;
	  	padding-bottom: 13px;
	  	margin-bottom: 16px;
		}

	  &::placeholder {
			opacity: 1;
  		transition: all .2s;
		}

		&:placeholder-shown:not(:focus) + * {
		  opacity: 1;
		  top: 32px;
		  color: $gray-dark;

		  @include media-breakpoint-up(xl) { 
				font-size: 20px;
			}

		  @include media-breakpoint-down(lg) { 
				font-size: 16px;
			}
		}

		&:placeholder-shown:not(:focus)::placeholder {
		  opacity: 0;
		}
	}

	input[type="text"],
	input[type="email"] {

		@include media-breakpoint-up(lg) { 
	  	margin-bottom: 2px;
		}

		@include media-breakpoint-down(lg) { 
	  	margin-bottom: 16px;
		}
	}

	.select-style {
  	overflow: hidden;

  	@include media-breakpoint-up(lg) { 
  		margin-bottom: 2px;
  		background: url("/assets/img/site/dropdown_arrow.svg") 100% 58% / 14px 8px no-repeat;
  	}

  	@include media-breakpoint-down(md) { 
			margin-bottom: 16px;
			background: url("/assets/img/site/dropdown_arrow.svg") 100% 66% / 14px 8px no-repeat;
		}
  }

	select {
		border-style: none none solid none;
		border-radius: 0;
		border-color: $gray-dark;
		background-color: $transparent;
		width: 120%;
		box-shadow: none;
		-webkit-appearance: none;
		font-weight: $font-weight-bold;
		appearance: none;
		margin: 0;

		@include media-breakpoint-up(xl) { 
			font-size: 20px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 16px;
		} 

		@include media-breakpoint-up(lg) { 
			border-width: 0px 0px 5px 0px;
			padding-top: 32px;
	  	padding-bottom: 22px;
		}

	  @include media-breakpoint-down(md) { 
			border-width: 0px 0px 3px 0px;
			padding-top: 32px;
	  	padding-bottom: 13px;
		}
	}

	@-moz-document url-prefix() { 

	  select {

	  	@include media-breakpoint-up(lg) { 
	    	padding-bottom: 22px;
    		padding-top: 34px;
    	}
	  }
	}

	textarea {
		resize: none;

		@include media-breakpoint-up(lg) { 
	  	height: 138px;
		}
		
		@include media-breakpoint-down(md) { 
			height: 102px;
		}
	}

	.form-feedback {
		position: absolute;
		right: 0;
		color: $brand-primary;
		font-size: 12px;
		font-weight: $font-weight-bold;
		line-height: 1.2;
		opacity: 0;
   	transition: opacity .5s ease-in-out;

   	@include media-breakpoint-up(lg) { 
	  	top: 43px;
		}

   	@include media-breakpoint-down(md) { 
			top: 38px;
		}
	}

	&.has-danger {

		input,
		textarea,
		select {
			border-color: $brand-primary;
		}

		.form-feedback {
			transition: opacity .5s ease-in-out;
			opacity: 1;
		}
	}
}

.w-50 {

	@include media-breakpoint-up(lg) { 
		width: calc(( 100% / 2 ) - 25px);
	}

	@include media-breakpoint-down(lg) { 
		width: 100%;
	}

	&:first-child {

		@include media-breakpoint-up(xl) { 
			margin-right: 50px;
		}
	}

	p {

		@include media-breakpoint-up(xl) { 
			font-size: 40px;
			line-height: 1.2;
		}

		@include media-breakpoint-down(lg) { 
			font-size: 16px;
			line-height: 26px;
		}
	}

	& + .w-25 {
		@include media-breakpoint-up(lg) {
			margin-left: 50px;
		}
	}
}

.w-75 {

	@include media-breakpoint-up(lg) { 
		width: calc( 75% - 25px);
	}

	@include media-breakpoint-down(lg) { 
		width: 100%;
	}

	& + .w-25 {

		@include media-breakpoint-up(lg) {
			margin-left: 50px;
		}
	}
}

.w-25 {

	@include media-breakpoint-up(lg) { 
		width: calc(( 100% / 4 ) - 25px);
	}

	@include media-breakpoint-down(lg) { 
		width: 100%;
	}
}

.contact-form {

	@include media-breakpoint-up(lg) { 
		padding-bottom: 164px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding 64px;
	}

	.has-float-label {

		input {

			@include media-breakpoint-up(lg) { 
		  	margin-bottom: 40px;
			}

			@include media-breakpoint-down(md) { 
		  	margin-bottom: 13px;
			}
		}

		textarea {

			@include media-breakpoint-up(lg) { 
		  	margin-bottom: 80px;
			}

			@include media-breakpoint-down(md) { 
		  	margin-bottom: 50px;
			}
		}
	} 
}