.space-img {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-wrap: wrap;
	z-index: 2;
}

.space-img-right {
	
	@include media-breakpoint-up(lg) {
		order: 1;
		justify-content: flex-start;
	}
}