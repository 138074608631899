.custom-input {
	position: relative;
	width: 100%;

	input {
		width: 100%;
		color: $gray-dark;
		font-weight: $font-weight-bold;
		border: none;
		background-color: $transparent;
		padding: 0;
		height: 40px;

		&::placeholder {
  		color: $gray-dark;
			opacity: 1 !important;
  	}

  	&:focus {
  		outline: none;

  		+ .press-enter {
  			transition: .5s linear;
				visibility: visible;
				opacity: 1;
			}
  	}
	}

	@include media-breakpoint-up(lg) {
		padding-top: 25px;

		input {
			font-size: 20px;
			height: 40px;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 12px;

		input {
			font-size: 14px;
			height: 30px;
		}
	}
}

.press-enter {
	display: block;
	visibility: hidden;
	opacity: 0;
	font-weight: $font-weight-bold;
	color: $brand-primary;
	
	@include media-breakpoint-up(lg) {
		padding-top: 12px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 5px;
	}
}