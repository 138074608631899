.challenge-meeting {
	position: relative;

	@include media-breakpoint-up(lg) {  
		margin-bottom: 150px;
		padding-top: 172px;
	}
	
	@include media-breakpoint-down(lg) {  
		padding-top: 76px;
	}
	
	&::before {
		content: "";
		position: absolute;
		display: block;
		background-color: $blue-lightest-color-with-transparent;
		z-index: -1;

		@include media-breakpoint-up(lg) {
			top: 0px;
			width: 37.5%;
			left: 37.5%;
			height: 122.72%;
		}

		@include media-breakpoint-down(lg) {
			width: 50%;
			height: 105%;
			top: 0;
			right: 0;
		}
	}

	img {
		@include media-breakpoint-down(lg) { 
			margin: 0 auto;
			display: block;
		}
	}

	.meetings {

		@include media-breakpoint-up(lg) { 
			max-width: 450px;
		}

		@include media-breakpoint-down(md) { 
			padding: 46px 30px 25px;
			max-width: 480px;
			margin: 0 auto;
		}
	}

	.title-h3 {
		line-height: 1.2;

		@include media-breakpoint-up(xl) {  
			font-size: 40px;
		}

		@include media-breakpoint-down(xl) {  
			font-size: 20px;
		}

		@include media-breakpoint-up(lg) { 
			margin-left: 33.3%;
			margin-bottom: 68px;
		}

		@include media-breakpoint-down(lg) { 
			margin-bottom: 44px;
		}

		@include media-breakpoint-down(md) {  
			text-align: center;
		}

		&-normal {
			line-height: 1.2;
			font-weight: $font-weight-regular;

			@include media-breakpoint-up(xl) {  
				font-size: 40px;
				margin-bottom: 61px;
			}

			@include media-breakpoint-down(xl) {  
				font-size: 20px;
			}

			@include media-breakpoint-down(lg) { 
				margin-bottom: 39px;
			}
		}
	}

	p {

		@include media-breakpoint-up(xl) {  
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 83px;
		}

		@include media-breakpoint-down(xl) {  
			line-height: 26px;
		}
	}
}