.upcoming-top {

	h2 {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 55px;
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 45px;
		padding-top: 45px;
	}
}

.upcoming-top-title {

	@include media-breakpoint-down(md) {
		text-align: center;
		font-size: 20px;
	}
}

.upcoming-events {
	position: relative;
	
	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 150px;

		&::before {
			content: "";
			width: 1px;
			height: 50px;
			background-color: $gray-dark;
			display: block;
			position: absolute;
			bottom: -100px;
			left: 50%;
		}
	}
}

.upcoming-events-without-separator {

	@include media-breakpoint-down(md) {
		margin-bottom: 50px;

		&::before {
			display: none;
		}
	}
}

.upcoming-item {
	display: flex;
	justify-content: center;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-top: 75px;
		padding-bottom: 75px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 47px;
		padding-bottom: 45px;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&::before {
		width: 100%;
		height: 100%;
		background-color: $blue;
	}

	&::after {
		background: url(/assets/img/site/patern.png) 0 0 repeat;
		z-index: 1;
		opacity: .8;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
	}

	picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		object-fit: cover;
		mix-blend-mode: overlay;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.upcoming-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	z-index: 2;

	@include media-breakpoint-up(lg) {
		width: 240px;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		padding: 0 30px;
	}
}

.room-name {
	display: block;
	padding-top: 3px;
}

.upcoming-title {
	line-height: 1.2;
	margin-bottom: 0;

	@include media-breakpoint-up(xl) {
		flex: 1 1 338px;
	}

	@include media-breakpoint-down(lg) {
		flex: 1 1 218px;
		padding: 75px 0 44px;
	}

	@include media-breakpoint-down(md) {
		font-size: 20px;
		flex: 1 1 auto;
		padding: 37px 0 44px;
	}
}

.upcoming-bottom-content {
	position: relative;

	&::before {
		display: block;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 2px;
		background-color: $gray-dark;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 48px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 28px;
	}
}

.upcoming-btn-block {

	@include media-breakpoint-down(lg) {
		padding: 0 $container-padding;
	}

	&.hidden-lg-up {

		@include media-breakpoint-down(lg) {
			padding-left: $container-padding;
			padding-right: $container-padding;
		}
		
		& > .btn {
			@include media-breakpoint-down(lg) {
				width: 100%;
				margin: 0;
			}
		}
	}
}