.nav-questions {
	display: flex;

	a {
		font-family: $font-family-brand-mono;
		display: block;
	}

	@include media-breakpoint-up(lg) {
		
		a {
			position: relative;
			font-size: 20px;
			font-weight: $font-weight-bold;
			margin-left: 25px;

			&:last-child {
				margin-right: 0;
			}
			
			&.is-active {
				visibility: visible;
				
				&::before {
					content: "";
					position: absolute;
					bottom: -5px;
					left: 0;
					display: block;
					width: 100%;
					height: 5px;
					background-color: $brand-primary;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
		flex: 0 1 34px;
		justify-content: center;
		align-items: center;
		margin-right: -8px;

		a {
			display: none;

			&.is-active {
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 23px;
				line-height: 1;
			}
		}

		.prev,
		.next {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 34px;
			height: 34px;
			cursor: pointer;

			&::before,
			&::after {
				display: block;
				content: "";
				width: 7px;
				height: 1px;
				background-color: $gray-dark;
			}
		}

		.prev {

			&::before {
				transform: rotate(135deg) translate3d(-1px, 0, 0);
			}

			&::after {
				transform: rotate(45deg) translate3d(-1px, 0, 0);
			}
		}

		.next {
			
			&::before {
				transform: rotate(45deg) translate3d(1px, 0, 0);
			}

			&::after {
				transform: rotate(135deg) translate3d(1px, 0, 0);
			}
		}
	}
}