.get-mail{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.link-submit {
		border: 0;
		color: $gray-dark;
		padding: 0;
		margin: 0;
		font-weight: $font-weight-bold;
		background-color: $transparent;
		cursor: pointer;

		@include media-breakpoint-up(xl) { 
			font-size: 20px;
		}

		@include media-breakpoint-down(lg) { 
			font-size: 16px;
		}
	}
}

.input-box {
	position: relative;

	@include media-breakpoint-up(xl) { 
		width: 350px;
	}

	@include media-breakpoint-down(lg) { 
		width: 150px;
	}

	.blink {
	  color: $gray-dark;
	  display: none;
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  pointer-events: none;
	  z-index: 2;

		& > div {
			display: inline-block;
			position: relative;
			font-weight: $font-weight-bold;
			color: $gray-dark;

			&:after {
			  content: '';
			  display: block;
			  position: absolute;
			  right: -10px;
			  width: 0;
			  border-right: 1px solid $gray-dark;
			  animation: blink 1.3s steps(2) 1s infinite;

			  @include media-breakpoint-up(xl) { 
					top: 7px;
			  	bottom: 7px;
				}

			  @include media-breakpoint-down(lg) { 
					top: 2px;
			  	bottom: 2px;
				}
			}
		}
	}

	input[type="email"] {
	  background-color: $transparent;
	  border: medium none;
	  outline: medium none;
		width: 100%;
		box-shadow: none;
		font-weight: $font-weight-bold;

		@include media-breakpoint-up(xl) { 
			font-size: 40px;
		}

		@include media-breakpoint-down(lg) { 
			font-size: 16px;
		}

	  &::placeholder {
			opacity: 1;
			color: $transparent;
		}

		&:placeholder-shown:not(:focus) + * {
			display: block;

			@include media-breakpoint-up(xl) { 
				font-size: 40px;
			}

		  @include media-breakpoint-down(lg) { 
				font-size: 16px;
			}
		}

		&:placeholder-shown:not(:focus)::placeholder {
		  opacity: 0;
		}
	}
}

@keyframes blink {
	0%{ border-right-width: 0}
	100%{ border-right-width: 1px}
}