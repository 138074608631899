.lower-alpha-list {
	counter-reset: letter;
	list-style-type: none;

	li {
		margin-bottom: 28px;
	}

	span {
		display: block;
		padding-top: 5px;
		line-height: 22px;
	}

	@include media-breakpoint-up(lg) {
		padding: 35px 0 0;

		.btn-group {
			padding-top: 24px;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 22px 0 0;

		.btn-group {
			padding-top: 17px;
		}
	}
}