.community-right {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(md) {
		justify-content: center;
	}

	img {
		transition: all .35s cubic-bezier(0.26, 1.48, 0.47, 0.96) 0s;
	}
}
