.about-team-desc {
	position: relative;
	display: flex;
	align-items: flex-start;

	@include media-breakpoint-up(lg) {
		padding: 175px 0 90px;
	}

	@include media-breakpoint-down(md) { 
		padding: 46px 30px 144px;
	}

	&::after,
	&::before {
		content: "";
		display: block;
		position: absolute;
	}

	&::before {
		top: 180px;
		left: -33.33%;
		width: 80px;
		height: 1px;
		background-color: $gray-dark;
	}

	&::after {
		background-color: $blue-color-with-transparent;

		@include media-breakpoint-up(lg) {
			top: calc(50% - 40px);
			right: -35.7%;
			width: 10px;
			height: 80px;
		}

		@include media-breakpoint-down(md) { 
			bottom: 50px;
			width: 5px;
			height: 50px;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
		}
	}

	.title-h3 {
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {
			max-width: 695px;
		}

		@include media-breakpoint-down(lg) { 
			font-size: 20px;
			line-height: 1.2;
		}
	}
}