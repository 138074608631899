.about-theme {
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 80px 0px 180px;

	@include media-breakpoint-up(lg) {
		padding: 80px 0px 180px;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		padding: 50px 0px 0px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0px;
		display: block;
		height: 100%;
		background-color: $blue-lightest-color-with-transparent;

		@include media-breakpoint-down(md) {
			right: 0;
			width: 75%;
		}

		@include media-breakpoint-up(lg) {
			right: 16.6666666667%;
			width: 50%;
		}
	}
}

.about-theme-img {

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	@include media-breakpoint-up(lg) {
		flex: 1;
	}
}

.about-theme-content {

	@include media-breakpoint-up(lg) {
		max-width: 75%;
		padding-left: 30px;
		margin-bottom: 21px;
	}

	@include media-breakpoint-down(md) {
		padding: 46px 30px;
	}
}

.about-theme-desc {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 26px;
	}
	
	@include media-breakpoint-down(md) {
		margin: 0 auto;
		max-width: 720px;
	}

	.title-h3 {

		@include media-breakpoint-up(lg) {
			margin-bottom: 64px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 20px;
			line-height: 1.2;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 38px;
		}
	}
}