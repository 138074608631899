.space-flex-end {
	display: flex;

	@include media-breakpoint-up(lg) {
		justify-content: flex-end;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
	}
}