.home-bg-vido {

  @include media-breakpoint-up(lg) {
  	position: absolute;
	  top: 50%;
	  left: 50%;
	  min-height: 100%;
	  min-width: 100%;
	  width: auto;
	  height: auto;
	  transform: translate(-50%, -50%);
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}