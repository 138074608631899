* {
  box-sizing: border-box;
  outline: none !important;
}

body {
	background: url(/assets/img/site/patern.png) repeat center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	@include media-breakpoint-down(md) {
		font-size: $font-size-base-mobile;
	}
}

/* Firefox hidde border */
a:focus, 
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	outline: 0;
}

/* User agent stlesheet Chrome clear yellow background */
@-webkit-keyframes autofill {

  to {
  	color: $gray-dark;
    background: $transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

picture {
	text-align: center;
	display: block; 
	width: 100%; 
}

.nowrap {
	white-space: nowrap;
}

.overflow {
	overflow: hidden;
}

.display-2 {

	@include media-breakpoint-down(lg) {
		font-size: 40px;
	}
}

.title-h3 {
	line-height: 36px;
}

.container-with-pattern {
	position: relative;
	background: url(/assets/img/site/patern.png) repeat center;
}

#scrollbar,
.container-with-pattern {
	z-index: 1;
}