.block-with-bottom-border {
	display: flex;
	align-items: flex-start;

	@include media-breakpoint-up(xl) {
		justify-content: flex-end;
	}

	@include media-breakpoint-down(lg) {
		justify-content: flex-start;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
		padding: 0 $container-padding;
	}
}