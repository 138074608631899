.community-left {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up(lg) {
		justify-content: flex-end;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
	}

	.big-text {
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			right: -215px;
			bottom: 135px;
		}

		@include media-breakpoint-down(md) {
			top: -183px;
			right: 50px;
			bottom: auto;
		}
	}
}