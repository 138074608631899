.contact-form {

	@include media-breakpoint-up(lg) { 
		padding-bottom: 164px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 $container-padding 80px;
	}

	.has-float-label {

		textarea {

			@include media-breakpoint-up(lg) { 
		  	margin-bottom: 80px;
			}

			@include media-breakpoint-down(md) { 
		  	margin-bottom: 37px;
			}
		}
	} 
}