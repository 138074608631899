.team-list {
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-bottom: 180px;
	}

	@include media-breakpoint-down(lg) { 
		padding: 0px 30px;
	}

	&::before {
		display: block;
		content: "";
		position: absolute;
		background-color: $primary-lightest-color-with-transparent;
		z-index: -1;

		@include media-breakpoint-up(lg) {
			left:0;
			top: 23.9%;
			width: 66.6666666668%;
			height: 46.6%;
		}

		@include media-breakpoint-down(lg) {
			left: 0px;
			width:50%;
			height:43.68%;
			top:18.21%;
		}
	}

	.item-left {

		.team-img {
			justify-content: flex-end;
			

			@include media-breakpoint-up(lg) { 
				text-align: right;
				margin-bottom: 100px;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 43px;
			}
		}

		.resume-content {

			@include media-breakpoint-up(lg) { 
				padding-left: 30px;
			}
		}
	}

	.item-right {

		.team-img {
			justify-content: flex-start;
			margin-bottom:100px;

			@include media-breakpoint-up(lg) {
				order: 1;
			}
		}

		.resume-content {

			@include media-breakpoint-up(lg) { 
				padding-right: 30px;
			}
		}
	}

	.team-img {
		
		@include media-breakpoint-down(md) {
			margin-bottom: 43px;
		}

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}

	.resume {
		display: flex;
		align-items: center;
		
		@include media-breakpoint-down(md) {
			max-width: 480px;
			margin: 0 auto 76px;
		}

		@include media-breakpoint-up(xl) { 
			justify-content: flex-end;
		}
	}

	.resume-content {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(xl) { 
			width: 564px;
		}
	}

	.resume-name {
		font-family: $font-family-brand-mono;
		font-weight: $font-weight-bold;
		display: block;

		@include media-breakpoint-up(xl) { 
			font-size: 92px;
			line-height: 1;
			margin-bottom: 5px;
		}

		@include media-breakpoint-down(xl) { 
			font-size: 40px;
			line-height: 1.2;
			margin-bottom: 4px;
		}
	}

	.position {
		display: block;

		@include media-breakpoint-up(xl) { 
			font-size: 20px;
			margin-bottom: 44px;
		}	

		@include media-breakpoint-down(xl) { 
			font-size: 16px;
			line-height: 1.2;
			margin-bottom: 25px;
		}
	}

	.resume-desc {
		position: relative;
		align-self: flex-end;

		@include media-breakpoint-up(xl) { 
			margin-right: 79px;
		}

		@include media-breakpoint-up(lg) {
			padding-top: 45px;
			max-width: 396px;
		}

		@include media-breakpoint-down(lg) {
			padding-top: 26px;
		}

		&::before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			background-color: $gray-dark;

			@include media-breakpoint-up(lg) { 
				width: 80px;
			}

			@include media-breakpoint-down(lg) {
				width: 50px; 
			}
		}

		p {

			@include media-breakpoint-up(xl) {  
				font-size: 20px; 
				line-height: 30px;
				margin-bottom: 33px;
			}
	
			@include media-breakpoint-down(xl) { 
				font-size: 16px;
				line-height: 1.625;
				margin-bottom: 14px;
			}
		}

		a {
			text-decoration: underline;
			font-weight: $font-weight-bold;
		}
	}
}